// intial state
const initialState = {
    active: true,
    whatWeDo: false,
    howItWorks: false,
    whereItWorks: false
}

// action types
const WHATWEDO = 'whatwedo';
const HOWITWORKS = 'howitworks';
const WHEREITWORKS = 'whereitworks';
const NAVBARACTIVE = 'navbaractive';
const NAVBARCLOSED = 'navbarclosed';
const DEFAULTNAVSTATUS = 'defaultnavstatus';

// redux actions
export const activateNavbar = () => ({
    type: NAVBARACTIVE
})

export const closeNavbar = () => ({
    type: NAVBARCLOSED
})

export const whatWeDoActivated = () => ({
    type: WHATWEDO
})

export const howItWorksActivated = () => ({
    type: HOWITWORKS
})

export const whereItWorksActivated = () => ({
    type: WHEREITWORKS
})

export const defaultNavStatusActivated = () => ({
    type: DEFAULTNAVSTATUS
})

const navStatusReducer = (state=initialState, action) => {
    switch(action.type) {
        case NAVBARACTIVE:
            return { ...state, active: true}
        case NAVBARCLOSED:
            return { ...state, active: false}
        case WHATWEDO:
            return { ...state, whatWeDo: true, howItWorks: false, whereItWorks: false}
        case HOWITWORKS:
            return { ...state, whatWeDo: false, howItWorks: true, whereItWorks: false}
        case WHEREITWORKS:
            return { ...state, whatWeDo: false, howItWorks: false, whereItWorks: true}
        case DEFAULTNAVSTATUS:
            return { ...state, whatWeDo: false, howItWorks: false, whereItWorks: false}
        default:
            return state
    }
}

export default navStatusReducer;