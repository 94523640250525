import { makeStyles } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { whatWeDoActivated } from "../../redux/ducks/navbarActive";

const useStyles = makeStyles(theme => {
    return {
        mainBgContainer: {
            position: 'relative',
            height: '100vh',
            width: '100vw',
            backgroundSize: 'cover',
            overflow: 'hidden',
        },
        bgImage: {
            top:'50%',
            left:'50%',
            width: '100%',
            height: '100%',
            minWidth:'1920px',
            minHeight:'1080px',
            position:'relative',
            transform:'translate(-50%, -50%)'
        },
        bannerContent: {
            bottom: '15vh',
            position: 'absolute',
            padding: '0 8%',
            maxWidth: '950px',
            fontFamily: 'Josefin Sans,sans-serif !important'
        },
        bannerText: {
            fontSize: '50px !important',
            fontFamily: 'Proxima Nova,Helvetica,Arial,sans-serif !important',
            letterSpacing: '-1.2px',
            fontWeight: '300 !important'
        },
        contentContainer: {
            padding: '90px 0px 90px',
            backgroundColor: 'white'
        },
        contentHeading: {
            maxWidth: '600px',
            margin: '40px auto',
            fontSize: '22px',
            lineHeight: '1.6',
            fontWeight: 300,
            color: '#9a9da6',
            padding: '0 5%',
            fontFamily: 'Josefin Sans,sans-serif !important'
        },
        contentParagraph: {
            color: '#666c78',
            lineHeight: '2.1',
            maxWidth: '600px',
            margin: '40px auto',
            padding: '0 5%',
            fontFamily: 'Proxima Nova,Helvetica,Arial,sans-serif !important',

        },
        contentLink: {
            color: 'red',
            textDecoration: 'none',
            fontFamily: 'Proxima Nova,Helvetica,Arial,sans-serif !important',
            '&:hover': {
                '& $contentLinkArrow': {
                    transform: 'translateX(10px) !important'
                }
            }
        },
        contentLinkArrow: {
            width: '30px',
            transform: '.4s ease-out',
            '-webkit-transform': '.4s ease-out',
            transition: '.4s ease-out',
            marginLeft: '10px',
            marginRight: 10
        },
        backToHomeContainer: {
            alignItems: 'center',
            marginBottom: 10,
            fontSize: '13px',
            textTransform: 'uppercase',
            fontFamily: 'Josefin Sans,sans-serif !important',
            '&:hover': {
                '& $carouselInfoLinkArrow': {
                    transform: 'translateX(-10px) rotate(180deg) !important'
                }
            }
        },
        backToHomeLink: {
            color: 'white',
            textDecoration: 'none'
        },
        carouselInfoLinkArrow: {
            width: '30px',
            '-webkit-transform': '.4s ease-out',
            transition: '.4s ease-out',
            marginLeft: '10px',
            transform: 'rotate(180deg)',
            marginRight: 10
        }
        
    }
});

const WhatWeDo = () => {
    const classes = useStyles();
    const dispatch = useDispatch();
    dispatch(whatWeDoActivated());

    return ( 
        <div className="whatWeDoContainer">
            <div className={classes.mainBgContainer}>
                <img src="/images/what_we_do_bg.jpg" className={classes.bgImage} alt="404"/>
            </div>
            <div className={classes.bannerContent}>
                
                <h1 className={classes.bannerText}>
                    <div className={classes.backToHomeContainer}>
                        <Link className={classes.backToHomeLink} to="/">
                            <svg viewBox="0 0 152.2 37.4" className={classes.carouselInfoLinkArrow}>
                                <g fill="#ffffff">
                                    <path d="M129 0l-1.4 3.3 17.1 13.3H0v4.2h144.7l-17.1 13.3 1.4 3.3 23.2-18.7z">
                                    </path>
                                </g>
                            </svg>

                            HOME
                        </Link>
                    </div>

                    <p>We Transform Human Interaction</p>
                </h1>
            </div>

            <section className={classes.contentContainer}>
                <div>
                    <h2 className={classes.contentHeading}>
                        Create happier, more loyal customers and agents
                    </h2>
                    <p className={classes.contentParagraph}>
                        Each time a customer interacts with your brand is an opportunity to win their trust. 
                    </p>
                    <p className={classes.contentParagraph}>
                        Get that customer talking to a customer service representative they click with, and you increase the chances of a positive outcome from the conversation.
                    </p>
                    <p className={classes.contentParagraph}>
                        The result? That customer will stay with you for longer, spend more, and broadcast the best of your brand to the world.
                    </p>
                    <p className={classes.contentParagraph}>
                        At Zariyat, we can help you make this happen. Our patented pairing technology puts your customers in touch with the agents uniquely best-suited to helping them, transforming the quality of interactions for all involved. 
                    </p>
                    <p className={classes.contentParagraph} style={{height: '250px'}}>
                        <iframe src="https://player.vimeo.com/video/674991690?loop=false&amp;autoplay=false&amp;muted=false&amp;gesture=media&amp;playsinline=true&amp;byline=false&amp;portrait=false&amp;title=false&amp;speed=true&amp;transparent=false&amp;customControls=true" height='100%' width='100%' allowFullScreen="" allow="autoplay; fullscreen; picture-in-picture" title="Player for What We Do" data-ready="true" tabIndex="-1"></iframe>
                    </p>
                    <h4 className={classes.contentParagraph}>
                        <Link to="/how-it-works" className={classes.contentLink}>
                            LEARN HOW IT WORKS
                            <svg viewBox="0 0 152.2 37.4" className={classes.contentLinkArrow}>
                                <g fill="#f62e00">
                                    <path d="M129 0l-1.4 3.3 17.1 13.3H0v4.2h144.7l-17.1 13.3 1.4 3.3 23.2-18.7z">
                                    </path>
                                </g>
                            </svg>
                        </Link>
                    </h4>
                </div>
            </section>
        </div>
     );
}
 
export default WhatWeDo;