import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';

import FullDrawer from './SideDrawer';
import { LinearProgress, MenuItem, Select } from '@mui/material';
import { makeStyles } from '@material-ui/core/styles';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { styled } from '@material-ui/styles';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';


const useStyles = makeStyles(theme => ({
        appbar: {
            top: 0,
            marginTop: 40,
            padding: '0 5%',
            background: 'transparent !important'
        },
        toolbar: {
            minHeight: 'auto !important',
            padding: '0 !important'
        },
        navLink: {
            textDecoration: 'none !important',
            color: props => props.navStat
        },
        navBtn: {
            fontFamily: 'Josefin Sans,sans-serif !important',
            fontSize: '12px !important',
            opacity: .8,
            padding: '0 15px !important',
            height: 38,
            background: 'transparent',
            border: 0,
            [theme.breakpoints.up('xs')]: {
                display: 'none',
            },
            [theme.breakpoints.up('sm')]: {
                display: 'none',
            },
            [theme.breakpoints.up('md')]: {
                display: 'block',
            },
            [theme.breakpoints.up('lg')]: {
                display: 'block',
            },
            '&:hover': {
                cursor: 'pointer'
            }
        },
        navProgressBar: {
            height: '2px !important'
        },
        langSelect: {
            border: '1px solid transparent !important',
            height: '40px !important',
            fontFamily: 'Josefin Sans,sans-serif !important',
            fontSize: '12px !important',
            width: 70,
            margin: '0px 50px',
            lineHeight: '40px !important',
            padding: 0,
            '&:before': {
                border: '1px solid transparent !important',
            },
            '&:after': {
                border: '1px solid transparent !important',
            }
        },
        langIcon: {
            width: 18,
            height: 11,
            marginRight: 5
        },
}))

const Navbar = () => {
    const navColor = useSelector(state => state.navStyle.color);
    const navWhatWeDo = useSelector(state => state.navStatus.whatWeDo);
    const navHowItWorks = useSelector(state => state.navStatus.howItWorks);
    const navWhereItWorks = useSelector(state => state.navStatus.whereItWorks);

    const classes = useStyles({navStat: navColor});
    const [lang, setAge] = React.useState('en');

    const WhiteKeyboardArrowDownIcon = styled(KeyboardArrowDownIcon)({
        color: `${navColor} !important`,
    });

    const langItems = [
        {
            val: 'en',
            txt: 'EN',
            img: 'https://flagicons.lipis.dev/flags/4x3/gb.svg',
        },
        {
            val: 'jp',
            txt: 'JP',
            img: 'https://flagicons.lipis.dev/flags/4x3/jp.svg',
        },
        {
            val: 'ur',
            txt: 'UR',
            img: 'https://flagicons.lipis.dev/flags/4x3/pk.svg',
        }
    ]

    const langChange = (event) => {
        setAge(event.target.value);
    };

    return ( 
            <div className="navbar">
                <Box sx={{ flexGrow: 1 }}>
                    <AppBar position="absolute" elevation={0} className={classes.appbar}>
                        <Toolbar className={classes.toolbar}>
                            <Typography variant="h6" component="div" sx={{ flexGrow: 1 }} style={{color: navColor}}>
                                <Link to="/">
                                    <img alt="" src={navColor == 'black' ? '/logo_dark.png' : '/logo.png'} className="rounded-circle user_img" style={{"border": "none", height: 32}}/>
                                </Link>
                            </Typography>
                            <div style={{margin: '0px 100px 0px 0px', display: 'flex'}}>
                                <Link to="/what-we-do/" className={classes.navLink}>
                                    <Box component="button" className={classes.navBtn} style={{color: navColor}}>
                                    WHAT WE DO
                                    </Box>
                                    <LinearProgress variant='determinate' value={navWhatWeDo ? 100 : 0} color='inherit' className={classes.navProgressBar}/>
                                </Link>
                                <Link to="/how-it-works/" className={classes.navLink}>
                                    <Box component="button" className={classes.navBtn} style={{color: navColor}}>
                                    HOW WE DO IT
                                    </Box>
                                    <LinearProgress variant='determinate' value={navHowItWorks ? 100 : 0} color='inherit' className={classes.navProgressBar}/>
                                </Link>
                                <Link to="/where-it-works/" className={classes.navLink}>
                                    <Box component="button" className={classes.navBtn} style={{color: navColor}}>
                                    WHERE IT WORKS
                                    </Box>
                                    <LinearProgress variant='determinate' value={navWhereItWorks ? 100 : 0} color='inherit' className={classes.navProgressBar}/>
                                </Link>
                            </div>
                            {/* <Select value={lang} onChange={langChange} variant='standard' style={{color: navColor}} className={classes.langSelect} IconComponent={WhiteKeyboardArrowDownIcon}>
                                {langItems.map((langItem, i) => 
                                    <MenuItem value={langItem.val} key={i}>
                                        <img src={langItem.img} className={classes.langIcon} alt="404, Not Found"></img>
                                        <span>{langItem.txt}</span>
                                    </MenuItem>)
                                }
                            </Select> */}
                            <FullDrawer className={classes.navLink}/>
                        </Toolbar>
                    </AppBar>
                </Box>
            </div>
     );
}
 
export default Navbar;