import { ReactCompareSlider, ReactCompareSliderImage } from 'react-compare-slider';
import { makeStyles } from '@material-ui/core/styles';
import { useEffect, useState } from 'react';
import axios from 'axios';
import { Button } from '@mui/material';


const useStyles = makeStyles(theme => ({
    esrBody: {
        paddingTop: '20vh',
        paddingBottom: '10vh',
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: '#282e45',
        borderBottom: '1px solid white',
        flexWrap: 'wrap',
        flexDirection: 'column'
    },
    esrContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        border: '2px solid #1967d2',
        boxSizing: 'border-box',
        borderRadius: '8px',
        boxShadow: '#1967d2 7px 5px 15px;',
        [theme.breakpoints.up('xs')]: {
            flexDirection: 'column'
        },
        [theme.breakpoints.up('sm')]: {
            flexDirection: 'column'
        },
        [theme.breakpoints.up('md')]: {
            flexDirection: 'row'
        },
        [theme.breakpoints.up('lg')]: {
            flexDirection: 'row'
        }
    },
    enchanceForm : {
        marginTop: '40px',
        color: 'white',
        fontSize: '1rem',
        textAlign: 'center',
        fontFamily: 'Josefin Sans,sans-serif !important',
    },
    enchanceFormBtn: {
        color: 'green !important',
        border: '1px solid green !important',
        borderRadius: '0 !important',
        height: '32.39px'
    },
    prevImgLink: {
        color: 'green'
    },
    pageHeading: {
        color: 'white',
        fontFamily: 'Josefin Sans,sans-serif !important',
        top: '20%',
        position: 'absolute',
        fontWeight: 'bold',
        fontSize: '2.5rem'
    },
    newImgInput: {
        display: 'none'
    },
    customImgInput: {
        border: '1px solid #ccc',
        display: 'inline-block',
        padding: '5px 15px',
        cursor: 'pointer',
    }
}))

const ImgResoEnhancer = () => {
    const classes = useStyles();
    const esrApi = 'https://api.zariyat.com/imgresoenhancer/'
    
    const [showUpload, setShowUpload] = useState(false);
    const [selectedFile, setSelectedFile] = useState(null);
    const [selectedFileName, setSelectedFileName] = useState("");

    useEffect(() => {
        axios.get(esrApi)
        .then(data => {if (!data.data) {setShowUpload(true)}})
        .catch(err => {console.log(err)})
    }, [])

    const handleFormSubmit = (e) => {
        e.preventDefault();

        setShowUpload(false);

        const formData = new FormData();
        formData.append("img", selectedFile);
        axios.post(esrApi, formData)
        .then((res) => {alert("Image converted Successfully")})
        .catch((err) => alert("File Upload Error"));
    }

    const handleFileInput = (e) => {
        setSelectedFile(e.target.files[0]);
        setSelectedFileName(e.target.files[0].name);
    }

    return (
        <div>
            <li className='top-spacer'></li>
            
            <div className={classes.esrBody}>
                <span className={classes.pageHeading}>Image Enhancer</span>
                <div className={classes.esrContainer}>
                    <ReactCompareSlider
                        itemOne={<ReactCompareSliderImage src="/esr_images/esr_output.jpg" srcSet="/esr_images/esr_output.jpg" alt="Image one" />}
                        itemTwo={<ReactCompareSliderImage style={{'filter': 'blur(1px)', 'WebkitFilter': 'blur(1px)'}} src="/esr_images/esr_source.jpg" srcSet="/esr_images/esr_source.jpg" alt="Image two" />}
                    />
                </div>

                {showUpload
                ?
                <form onSubmit={handleFormSubmit} encType="multipart/form-data" className={classes.enchanceForm}>
                    <p style={{'marginBottom': '50px'}}>You can preview the previously converted image <a className={classes.prevImgLink} href="https://api.zariyat.com/esr_output.png" target="_blank" rel="noreferrer">Here!</a></p>
                    <div className='selectedImg'>{selectedFileName}</div>
                    <label htmlFor="file-upload" className={classes.customImgInput}>
                        Select new Image
                    </label>
                    <div></div>
                    <input id="file-upload" accept="image/*" type="file" className={classes.newImgInput} onChange={handleFileInput}/><br/>
                    <Button type="submit" variant='outlined' className={classes.enchanceFormBtn}>Enhance!</Button>
                </form>
                :
                <div className={classes.enchanceForm}>
                    <p>Server in busy right now please comeback later to see the results!</p>
                    <p>You can preview the previously converted image <a className={classes.prevImgLink} href="https://api.zariyat.com/media/esr_output.png" target="_blank" rel="noreferrer">Here!</a></p>
                </div>
                }
                
            </div>
        </div>
     );
}
 
export default ImgResoEnhancer;