import axios from 'axios';
import '../../css/chatbot.css';
import { makeStyles } from '@material-ui/core/styles';
import Cookies from 'js-cookie';
import { useEffect } from 'react';


const useStyles = makeStyles(theme => ({
    card: {
        height: '80vh !important',
        borderRadius: '15px !important',
        backgroundColor: 'rgba(0,0,0,0.4) !important',
        marginTop: '80px !important',
        [theme.breakpoints.up('xs')]: {
            width: '97vw',
        },
        [theme.breakpoints.up('sm')]: {
            width: '95vw',
        },
        [theme.breakpoints.up('md')]: {
            width: '90vw',
        },
        [theme.breakpoints.up('lg')]: {
            width: '90vw',
        },
        '&:hover': {
            cursor: 'pointer'
        }
    },
    cardFooter: {
        [theme.breakpoints.up('xs')]: {
            width: '100% !important',
            marginLeft: '-6px !important',
            bottom: '-55px !important'
        },
        [theme.breakpoints.up('sm')]: {
            width: '95% !important',
            marginLeft: '0px !important',
            bottom: '0px !important'
        },
        [theme.breakpoints.up('md')]: {
            width: '90% !important',
            marginLeft: '0px !important',
            bottom: '0px !important'
        },
        [theme.breakpoints.up('lg')]: {
            width: '90% !important',
            marginLeft: '0px !important',
            bottom: '0px !important'
        },
        [theme.breakpoints.up('xl')]: {
            width: '90% !important',
            marginLeft: '0px !important',
            bottom: '0px !important'
        }
    }
}));

const ChatBot = () => {
    const classes = useStyles();
    const chatbotUrl = 'https://api.zariyat.com/chatbot/';

    useEffect(() => {
        let chat_history = Cookies.get("chat_history");
        if (chat_history) {
            chat_history = eval(chat_history);

            var msgsContainer = document.getElementsByClassName('msg_card_body')[0]; // main msgs container

            for (let i = 0; i < chat_history.length; i++) {
                let chat = chat_history[i];
                let from = chat['from'];
                let text = chat['text'];

                if (from == 'user') {
                    // display new usermsg
                    var userMsgContainer = document.createElement("div");
                    userMsgContainer.className = 'd-flex justify-content-end mb-4';
                    var userMsgDiv = document.createElement("div");
                    userMsgDiv.className = 'msg_cotainer_send';
                    userMsgDiv.innerHTML = text;
                    userMsgContainer.appendChild(userMsgDiv);
                    var userMsgImgContainer = document.createElement("div");
                    userMsgImgContainer.className = 'img_cont_msg';
                    var userMsgImg = document.createElement("img");
                    userMsgImg.className = 'rounded-circle user_img_msg'
                    userMsgImg.src = "/chatbot_images/usr_img.png";
                    userMsgImgContainer.appendChild(userMsgImg);
                    userMsgContainer.appendChild(userMsgImgContainer);
                    msgsContainer.appendChild(userMsgContainer);

                    // scroll to bottom on new message
                    msgsContainer.scrollTop = msgsContainer.scrollHeight;

                } else if (from == 'bot') {
                    // display bot reply
                    var userMsgContainer = document.createElement("div");
                    userMsgContainer.className = 'd-flex justify-content-start mb-4';
                    var userMsgImgContainer = document.createElement("div");
                    userMsgImgContainer.className = 'img_cont_msg';
                    var userMsgImg = document.createElement("img");
                    userMsgImg.className = 'rounded-circle user_img_msg'
                    userMsgImg.src = "/logo.png";
                    userMsgImgContainer.appendChild(userMsgImg);
                    userMsgContainer.appendChild(userMsgImgContainer);
                    var userMsgDiv = document.createElement("div");
                    userMsgDiv.className = 'msg_cotainer';
                    userMsgDiv.innerHTML = text;
                    userMsgContainer.appendChild(userMsgDiv);
                    msgsContainer.appendChild(userMsgContainer);

                    // scroll to bottom on new message
                    msgsContainer.scrollTop = msgsContainer.scrollHeight;
                }
            }
        }
    }, [])

    const handleSendMessage = (e) => {
        e.preventDefault();

        let userMessage = document.getElementById('user_msg').value;

        var msgsContainer = document.getElementsByClassName('msg_card_body')[0]; // main msgs container

        // display new usermsg
        var userMsgContainer = document.createElement("div");
        userMsgContainer.className = 'd-flex justify-content-end mb-4';
        var userMsgDiv = document.createElement("div");
        userMsgDiv.className = 'msg_cotainer_send';
        userMsgDiv.innerHTML = userMessage;
        userMsgContainer.appendChild(userMsgDiv);
        var userMsgImgContainer = document.createElement("div");
        userMsgImgContainer.className = 'img_cont_msg';
        var userMsgImg = document.createElement("img");
        userMsgImg.className = 'rounded-circle user_img_msg'
        userMsgImg.src = "/chatbot_images/usr_img.png";
        userMsgImgContainer.appendChild(userMsgImg);
        userMsgContainer.appendChild(userMsgImgContainer);
        msgsContainer.appendChild(userMsgContainer);

        // scroll to bottom on new message
        msgsContainer.scrollTop = msgsContainer.scrollHeight;

        // get chat history from cookies
        let chat_history = Cookies.get("chat_history");
        if (!chat_history) {
            chat_history = []
        }

        // call bot to respond
        axios.post(chatbotUrl, {text: userMessage, chat_history: chat_history})
        .then(resp => {
            var bot_resp = resp.data[0];
            console.log(bot_resp)

            // set history as received from the api and set expirt to 365 days
            Cookies.set('chat_history', resp.data[1], { expires: 365 })

            // display bot reply
            var userMsgContainer = document.createElement("div");
            userMsgContainer.className = 'd-flex justify-content-start mb-4';
            var userMsgImgContainer = document.createElement("div");
            userMsgImgContainer.className = 'img_cont_msg';
            var userMsgImg = document.createElement("img");
            userMsgImg.className = 'rounded-circle user_img_msg'
            userMsgImg.src = "/logo.png";
            userMsgImgContainer.appendChild(userMsgImg);
            userMsgContainer.appendChild(userMsgImgContainer);
            var userMsgDiv = document.createElement("div");
            userMsgDiv.className = 'msg_cotainer';
            userMsgDiv.innerHTML = bot_resp;
            userMsgContainer.appendChild(userMsgDiv);
            msgsContainer.appendChild(userMsgContainer);

            // scroll to bottom on new message
            msgsContainer.scrollTop = msgsContainer.scrollHeight;
        })

        document.getElementById('user_msg').value = ''; // set value to null
    }

    return ( 
        <div>
            <li className='top-spacer'></li>
            <div className='chatbot-container'>

                <link rel="stylesheet" href="https://stackpath.bootstrapcdn.com/bootstrap/4.1.3/css/bootstrap.min.css" integrity="sha384-MCw98/SFnGE8fJT3GXwEOngsV7Zt27NXFoaoApmYm81iuXoPkFOJwJ8ERdknLPMO" crossOrigin="anonymous"/>
                <link rel="stylesheet" href="https://use.fontawesome.com/releases/v5.5.0/css/all.css" integrity="sha384-B4dIYHKNBt8Bc12p+WXckhzcICo0wtJAoU8YZTY5qE0Id1GSseTk6S+L3BlXeVIU" crossOrigin="anonymous"></link>

                
                <div className="container-fluid h-100">

                    <div className="row justify-content-center h-100">
                        
                        <div className="chat">
                            <div className={classes.card}>
                                <div className="card-header msg_head">
                                    <div className="d-flex bd-highlight">
                                        <div className="img_cont">
                                            <img alt="" src='/logo.png' className="rounded-circle user_img" style={{"border": "none"}}/>
                                        </div>
                                        <div className="user_info">
                                            <span>Zariyat Chatbot</span>
                                            <p>Ask me anything!</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="card-body msg_card_body">
                                    {/* conversations will display here */}
                                </div>
                                <div className={`${classes.cardFooter} card-footer`}>
                                    <form className="input-group" onSubmit={handleSendMessage}>
                                        <textarea name="user_msg" id="user_msg" className="form-control type_msg" placeholder="Type your message..." style={{"resize": "none"}}></textarea>
                                        <div className="input-group-append">
                                            
                                        </div>
                                        <button className="chatbot-submit-btn" type='submit'>
                                            <span className="input-group-text send_btn"><img alt="" src="/chatbot_images/send_msg.png" className="send-icon"/></span>
                                        </button>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
     );
}
 
export default ChatBot;