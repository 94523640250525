import * as React from 'react';
import Grid from '@mui/material/Grid';
import '../../css/pricing.scss';
import { makeStyles } from '@material-ui/core/styles';
import { Button } from '@material-ui/core';


const pricesData = [
    {
        "type": "Bronze",
        "price": 100,
        "description":"A single license for solo designers, developers and freelancers.",
        "options": [
            {"k": "Single user licence", "v": "One user access."},
            {"k": "Lifetime access", "v": "One fee. One time. Yours forever."},
            {"k": "Free updates", "v": "You will gain access to every future update."},
            {"k": "Use on unlimited projects", "v": "Create as many projects as you want"},
            {"k": "Asset hosting", "v": "Keep your files for easy access and management. 5GB space included. Scale when necessary."},
            {"k": "Community support", "v": "Get help and learn new things in our community Slack with over 5,000 contributors."},
            {"k": "Version control", "v": "Instant rollbacks to any version"}
        ]
    },
    {
        "type": "Gold",
        "price": 200,
        "description":"A single license for solo designers, developers and freelancers.",
        "options": [
            {"k": "Single user licence", "v": "One user access."},
            {"k": "Lifetime access", "v": "One fee. One time. Yours forever."},
            {"k": "Free updates", "v": "You will gain access to every future update."},
            {"k": "Use on unlimited projects", "v": "Create as many projects as you want"},
            {"k": "Asset hosting", "v": "Keep your files for easy access and management. 5GB space included. Scale when necessary."},
            {"k": "Community support", "v": "Get help and learn new things in our community Slack with over 5,000 contributors."},
            {"k": "Version control", "v": "Instant rollbacks to any version"}
        ]
    },
    {
        "type": "Platinum",
        "price": 300,
        "description":"A single license for solo designers, developers and freelancers.",
        "options": [
            {"k": "Single user licence", "v": "One user access."},
            {"k": "Lifetime access", "v": "One fee. One time. Yours forever."},
            {"k": "Free updates", "v": "You will gain access to every future update."},
            {"k": "Use on unlimited projects", "v": "Create as many projects as you want"},
            {"k": "Asset hosting", "v": "Keep your files for easy access and management. 5GB space included. Scale when necessary."},
            {"k": "Community support", "v": "Get help and learn new things in our community Slack with over 5,000 contributors."},
            {"k": "Version control", "v": "Instant rollbacks to any version"}
        ]
    }
]

const useStyles = makeStyles(theme => ({
    pricesContainer: {
        paddingTop: '20vh !important',
        paddingBottom: '10vh !important',
        borderBottom: '1px solid white'
    },
    pageHeading: {
        color: 'white',
        fontFamily: 'Josefin Sans,sans-serif !important',
        top: '18%',
        position: 'absolute',
        fontWeight: 'bold',
        fontSize: '2.5rem',
        width: '100%',
        textAlign: 'center',
    },
    continueBtn: {
        backgroundImage: 'linear-gradient(to right, #d53a9d 0%, #743ad5  51%, #d53a9d  100%)',
        margin: '10px',
        padding: '15px 35px',
        textAlign: 'center',
        textTransform: 'uppercase',
        transition: '0.5s',
        backgroundSize: '200% auto',
        color: 'white',
        borderRadius: '10px',
        display: 'block',
        marginTop: '30px !important',
        marginBottom: '0px !important'
,        '&:hover': {
            backgroundPosition: 'right center',
            color: '#fff',
            textDecoration: 'none',
        }
      },      
}));


const Pricing = () => {
    const classes = useStyles();

    return (
        <div>
            <li className='top-spacer'></li>
            <span className={classes.pageHeading}>Pricing</span>
        
            <Grid sx={{ flexGrow: 1 }} container p={2} className={classes.pricesContainer}>
                <Grid item xs={12}>
                    <Grid container justifyContent="center" spacing={12}>
                        {pricesData.map((priceData, i) => 
                            <Grid item key={i}>
                                <div className="price-section">
                                    <article className="price-table">
                                        <div className="price"><small>$</small>{priceData.price}</div>
                                        <h2 className="title">{priceData.type}</h2>
                                        <p className="description">{priceData.description}</p>
                                        <div className="features">
                                            {priceData.options.map((option, j) =>
                                                <details className="feature" key={j}>
                                                    <summary>
                                                        <i aria-hidden="true" className="checkmark">
                                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
                                                                <path fill="none" d="M0 0h24v24H0z" />
                                                                <path fill="currentColor" d="M10 15.172l9.192-9.193 1.415 1.414L10 18l-6.364-6.364 1.414-1.414z" />
                                                            </svg>
                                                        </i>
                                                        <span className="name">{option.k}</span>
                                                        <i aria-hidden="true" className="question-icon">
                                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
                                                                <path fill="none" d="M0 0h24v24H0z" />
                                                                <path fill="currentColor" d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16zm-1-5h2v2h-2v-2zm2-1.645V14h-2v-1.5a1 1 0 0 1 1-1 1.5 1.5 0 1 0-1.471-1.794l-1.962-.393A3.501 3.501 0 1 1 13 13.355z" />
                                                            </svg>
                                                        </i>
                                                    </summary>
                                                    <div className="answer">
                                                        {option['v']}
                                                    </div>
                                                </details>
                                            )}
                                        </div>
                                        <Button className={classes.continueBtn}>Continue</Button>
                                    </article>
                                </div>
                            </Grid>
                        )}

                    </Grid>
                </Grid>
            </Grid>
        </div>
      );
}
 
export default Pricing;