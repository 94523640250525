import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography } from '@mui/material';
import { Link } from 'react-router-dom';


const customBorder= '1px solid white'

const useStyles = makeStyles(theme => {
    return {
        container: {
            height: '100%',
            backgroundColor: "#282e45",
            [theme.breakpoints.up('xs')]: {
                display: 'none'
            },
            [theme.breakpoints.up('sm')]: {
                display: 'none'
            },
            [theme.breakpoints.up('md')]: {
                display: 'block'
            },
            [theme.breakpoints.up('lg')]: {
                display: 'block'
            },
            [theme.breakpoints.up('xl')]: {
                display: 'block'
            }
        },
        gridContainer: {
            zIndex: 10
        },
        gridItem: {
            height: '33.3vh',
            maxHeight: '33.3vh',
            zIndex: 9,
            '&:hover': {
                backgroundColor: 'rgba(2,10,32,.4)',
                transition: 'all .3s linear',
            }
        },
        gridItemLink: {
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            padding: '0 0 0 25%',
            textDecoration: 'none',
            opacity: 1,
            '&:hover': {
                '& $gridItemLinkSubTitle': {
                    visibility: 'visible',
                    transform: 'translateY(-10px)',
                    transition: 'all .3s linear',
                },
                '& $gridItemLinkTitle': {
                    transform: 'translateY(-10px)',
                    transition: 'all .3s linear',
                }
            }
        },
        gridItemLinkTitle: {
            color: 'white',
            fontWeight: 400,
            fontSize: 18,
            fontFamily: 'Josefin Sans,sans-serif',
            textTransform: 'uppercase',
            transform: 'translateY(0px)',
            marginTop: 10,
            textShadow: '0 1px 5px rgb(2 10 32 / 75%)',
            opacity: 1
        },
        gridItemLinkSubTitle: {
            color: 'white',
            visibility: 'hidden',
            margin: '14px 0px !important',
            transition: 'all .3s linear',
            paddingRight: '25%',
            fontSize: '14px',
            fontFamily: 'Proxima Nova,sans-serif',
            transform: 'translateY(0px)',
        },
        video: {
            position: 'absolute',
            minWidth: '100%',
            objectPosition: 'right bottom',
            objectFit: 'cover',
            width: '100%',
            minHeight: '100vh'
        }
    }
})

const MenuSection = () => {
    const classes = useStyles();
    
    return ( 
        <div className={classes.container}>
            {/* <video autoPlay loop muted playsInline className={classes.video}>
                <source src="https://media.afiniti.com/home/videos/v4/video3.mp4" type="video/mp4"/>
            </video> */}

            <Grid container className={classes.gridContainer} style={{borderTop: customBorder}} direction="row" alignItems="center" justifyContent="center">

                <Grid item xs={4} sm={4} md={4} lg={4} className={classes.gridItem} style={{borderBottom: customBorder}} to="/">
                    <Link to="/what-we-do/" className={classes.gridItemLink}>
                        <Typography variant="span" className={classes.gridItemLinkTitle}>What We Do</Typography>
                        <Typography variant="p" className={classes.gridItemLinkSubTitle}>Our artificial intelligence platform identifies subtle and valuable patterns in human behavior to measurably increase enterprise profitability.</Typography>
                    </Link>
                </Grid>

                <Grid item xs={4} sm={4} md={4} lg={4} className={classes.gridItem} style={{borderRight: customBorder, borderBottom: customBorder, borderLeft: customBorder}} to="/" >
                    <Link to="/how-it-works/" className={classes.gridItemLink} style={{ border: '1px solid black !important'}}>
                        <Typography variant="span" className={classes.gridItemLinkTitle}>How It Works</Typography>
                        <Typography variant="p" className={classes.gridItemLinkSubTitle}>Over 200 patents describe the unique artificial intelligence and machine learning strategies we use to create measurable value.</Typography>
                    </Link>
                </Grid>

                <Grid item xs={4} sm={4} md={4} lg={4} className={classes.gridItem} style={{borderBottom: customBorder}} to="/">
                    <Link to="/where-it-works/" className={classes.gridItemLink}>
                        <Typography variant="span" className={classes.gridItemLinkTitle}>Where It Works</Typography>
                        <Typography variant="p" className={classes.gridItemLinkSubTitle}>The metrics and consumer facing industries we optimize across the world.</Typography>
                    </Link>
                </Grid>
                
            </Grid>

            <Grid container className={classes.gridContainer} direction="row" alignItems="center" justifyContent="center">

                <Grid item xs={4} sm={4} md={4} lg={4} className={classes.gridItem} style={{borderBottom: customBorder}} to="/">
                    <Link to="/management/" className={classes.gridItemLink}>
                        <Typography variant="span" className={classes.gridItemLinkTitle}>Team</Typography>
                        <Typography variant="p" className={classes.gridItemLinkSubTitle}>Alone we can do so little, together we can do so much.</Typography>
                    </Link>
                </Grid>

                <Grid item xs={4} sm={4} md={4} lg={4} className={classes.gridItem} style={{borderRight: customBorder, borderBottom: customBorder, borderLeft: customBorder}} to="/">
                    <Link to="/" className={classes.gridItemLink}>
                        <Typography variant="span" className={classes.gridItemLinkTitle}>Clients</Typography>
                        <Typography variant="p" className={classes.gridItemLinkSubTitle}>The people for whom Zariyat Enterprise Behavioral Pairing™ is delivering billions of dollars in incremental value, today.</Typography>
                    </Link>
                </Grid>

                <Grid item xs={4} sm={4} md={4} lg={4} className={classes.gridItem} style={{borderBottom: customBorder}} to="/">
                    <Link to="/" className={classes.gridItemLink}>
                        <Typography variant="span" className={classes.gridItemLinkTitle}>Partners</Typography>
                        <Typography variant="p" className={classes.gridItemLinkSubTitle}>Find out how we are partnering with some of the world's leading organizations to help deliver our solution.</Typography>
                    </Link>
                </Grid>
                
            </Grid>

            <Grid container className={classes.gridContainer} direction="row" alignItems="center" justifyContent="center">

                <Grid item xs={4} sm={4} md={4} lg={4} className={classes.gridItem} to="/" style={{borderBottom: customBorder}}>
                    <Link to="/" className={classes.gridItemLink}>
                        <Typography variant="span" className={classes.gridItemLinkTitle}>The Newsroom</Typography>
                        <Typography variant="p" className={classes.gridItemLinkSubTitle}>Get the latest news about Zariyat, and find out what we are doing and saying in the AI industry.</Typography>
                    </Link>
                </Grid>

                <Grid item xs={4} sm={4} md={4} lg={4} className={classes.gridItem} style={{borderRight: customBorder, borderLeft: customBorder, borderBottom: customBorder}} to="/">
                    <Link to="/" className={classes.gridItemLink}>
                        <Typography variant="span" className={classes.gridItemLinkTitle}>Cinema</Typography>
                        <Typography variant="p" className={classes.gridItemLinkSubTitle}>Use our video library to explore our business, clients, and products.</Typography>
                    </Link>
                </Grid>

                <Grid item xs={4} sm={4} md={4} lg={4} className={classes.gridItem} to="/" style={{borderBottom: customBorder}}>
                    <Link to="/" className={classes.gridItemLink}>
                        <Typography variant="span" className={classes.gridItemLinkTitle}>Don't Click This</Typography>
                        <Typography variant="p" className={classes.gridItemLinkSubTitle}>Didn't curiosity kill the cat?</Typography>
                    </Link>
                </Grid>
                
            </Grid>
        </div>
     );
}
 
export default MenuSection;