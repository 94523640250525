import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
    Grid,
    Card,
    CardContent,
    Typography,
} from '@material-ui/core/'
import { Link } from 'react-router-dom';

const useStyles = makeStyles(theme => ({
    productsContainer: {
        paddingTop: '20vh',
        paddingBottom: '10vh',
        height: '100%',
        width: "100%",
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: '#282e45',
        borderBottom: '1px solid white'
    },
    gridItem: {
        height: '33.3vh',
        maxHeight: '33.3vh',
        zIndex: 9,
        border: '1px solid white',
        borderRadius: 0,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: 'transparent',
        margin: 0,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'right bottom',
        backgroundSize: '180px',
        '&:hover': {
            backgroundColor: 'rgba(2,10,32,.4)',
            transition: 'all .3s linear',
        }
    },
    gridItemLink: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        textDecoration: 'none',
        opacity: 1,
        '&:hover': {
            '& $gridItemLinkTitle': {
                transform: 'translateX(10px)',
                transition: 'all .1s linear',
            }
        }
    },
    gridItemLinkTitle: {
        color: 'white',
        fontWeight: 400,
        fontSize: 18,
        fontFamily: 'Josefin Sans,sans-serif',
        textTransform: 'uppercase',
        transform: 'translateY(0px)',
        marginTop: 10,
        textShadow: '0 1px 5px rgb(2 10 32 / 75%)',
        opacity: 1
    },
    pageHeading: {
        color: 'white',
        fontFamily: 'Josefin Sans,sans-serif !important',
        top: '18%',
        position: 'absolute',
        fontWeight: 'bold',
        fontSize: '2.5rem',
        width: '100%',
        textAlign: 'center',
    },
    gridItemBgImg: {
        position: 'absolute',
        maxWidth: '100%',
        maxHeight: '100%'
    }
})
)

const Products = () => {
    const classes = useStyles()

    return (
        <div>
            <li className='top-spacer'></li>

            <span className={classes.pageHeading}>Our Products</span>

            <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="flex-start"
                className={classes.productsContainer}
            >
                
                <Grid item xs={11} sm={11} md={5} lg={4} style={{padding: '20px', zIndex: 0}}>
                    <Link to="/products/chatbot/" className={classes.gridItemLink}>
                        <Card className={classes.gridItem} style={{backgroundImage: "url('/products/bg/chatbot_bg_2.png')"}}>
                            <CardContent >
                                <Typography variant="h5" gutterBottom className={classes.gridItemLinkTitle}>
                                    Chatbot
                                </Typography>
                            </CardContent>
                        </Card>
                    </Link>
                </Grid>
                

                <Grid item xs={11} sm={11} md={5} lg={4} style={{padding: '20px', zIndex: 0}}>
                    <Link to="/products/translation/" className={classes.gridItemLink}>
                        <Card className={classes.gridItem} style={{backgroundImage: "url('/products/bg/translate_bg_2.png')"}}>
                            <CardContent >
                                <Typography variant="h5" gutterBottom className={classes.gridItemLinkTitle}>
                                    Translation
                                </Typography>
                            </CardContent>
                        </Card>
                    </Link>
                </Grid>

                <Grid item xs={11} sm={11} md={5} lg={4} style={{padding: '20px', zIndex: 0}}>
                    <Link to="/products/text-summarization/" className={classes.gridItemLink}>
                        <Card className={classes.gridItem} style={{backgroundImage: "url('/products/bg/summarize_bg_2.png')"}}>
                            <CardContent >
                                <Typography variant="h5" gutterBottom className={classes.gridItemLinkTitle}>
                                    Text Summarization
                                </Typography>
                            </CardContent>
                        </Card>
                    </Link>
                </Grid>

                <Grid item xs={11} sm={11} md={5} lg={4} style={{padding: '20px', zIndex: 0}}>
                    <Link to="/products/img-enhancer/" className={classes.gridItemLink}>
                        <Card className={classes.gridItem} style={{backgroundImage: "url('/products/bg/imgreso_bg_2.png')"}}>
                            <CardContent >
                                <Typography variant="h5" gutterBottom className={classes.gridItemLinkTitle}>
                                    Image Enhancer
                                </Typography>
                            </CardContent>
                        </Card>
                    </Link>
                </Grid>
            </Grid>
        </div>
    )
}
 
export default Products;