import Home from "./components/pages/Home";
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom';
import Contact from "./components/pages/Contact";
import Navbar from "./components/utils/Navbar";
import { createTheme, ThemeProvider } from "@mui/material";
import Footer from "./components/utils/Footer";
import Products from "./components/products/Products";
import Translation from "./components/products/Translation";
import ScrollToTop from "./ScrollToTop";
import ChatBot from "./components/products/ChatBot";
import TextSummarization from "./components/products/TextSummarization";
import ImgResoEnhancer from "./components/products/ImgResoEnhancer";
import Pricing from "./components/pages/Pricing";
import WhatWeDo from "./components/pages/WhatWeDo";
import HowItWorks from "./components/pages/HowItWorks";
import WhereItWorks from "./components/pages/WhereItWorks";
import Management from "./components/pages/Management";
import InstaScrapper from "./components/clients/Instascrapper";


const customMuiTheme = createTheme({
  Link: {
    textDecoration: 'none'
  }
})


function App() {

  return (
    <ThemeProvider theme={customMuiTheme}>
      <Router>
        <ScrollToTop />
        <div className="App">
          <Navbar />
          <Switch>
            <Route exact path="/">
              <Home />
              <Footer />
            </Route>
            <Route exact path="/contact">
              <Contact />
              <Footer />
            </Route>
            <Route exact path="/products">
              <Products />
              <Footer />
            </Route>
            <Route exact path="/products/translation">
              <Translation />
              <Footer />
            </Route>
            <Route exact path="/products/chatbot">
              <ChatBot />
              <Footer />
            </Route>
            <Route exact path="/products/text-summarization">
              <TextSummarization />
              <Footer />
            </Route>
            <Route exact path="/products/img-enhancer">
              <ImgResoEnhancer />
              <Footer />
            </Route>
            <Route exact path="/pricing">
              <Pricing />
              <Footer />
            </Route>
            <Route exact path="/what-we-do">
              <WhatWeDo />
              <Footer />
            </Route>
            <Route exact path="/how-it-works">
              <HowItWorks />
              <Footer />
            </Route>
            <Route exact path="/where-it-works">
              <WhereItWorks />
              <Footer />
            </Route>
            <Route exact path="/management">
              <Management />
              <Footer />
            </Route>

            {/* client specific urls */}
            <Route exact path="/clients/instascrapping">
              <InstaScrapper />
              <Footer />
            </Route>

            {/* 404 url */}
            <Route exact path="*">
              <h1>Page Not Found</h1>
            </Route>
          </Switch>
        </div>
      </Router>
    </ThemeProvider>
  );
}

export default App;
