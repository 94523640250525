import IndexCarousel from "../utils/IndexCarousel";
import MenuSection from "../utils/menuSection";

const Home = () => {
  return (
    <div className="home">
      <div className="section">
        <IndexCarousel />
      </div>
      <div className="section">
        <MenuSection />
      </div>
    </div>
  );
}
 
export default Home;