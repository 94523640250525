import * as React from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import { makeStyles } from '@material-ui/core/styles';
import { Grid } from '@mui/material';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { activateNavbar, closeNavbar } from '../../redux/ducks/navbarStyle';


const useStyles = makeStyles(theme => {
    return {
        toggleBtn: {
            padding: '0px !important',
            color: "white",
            marginRight: '0 !important'
        },
        drawerBox: {
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          height: '100vh',
          width: '100vw'
        },
        drawerContainer: {
          zIndex: 0
        },
        drawerLink: {
          fontSize: '3vh',
          lineHeight: '1.8em',
          margin: '0px 32px !important',
          padding: '0px 0px 35px !important',
          fontFamily: 'Josefin Sans,sans-serif',
          textDecoration: 'none',
          color: 'inherit',
          fontWeight: '300',
          display: 'inline-block',
          textAlign: 'center',
          // [theme.breakpoints.up('xs')]: {
          //     width: '80vw'
          // },
          // [theme.breakpoints.up('sm')]: {
          //     width: 'auto'
          // },
          // [theme.breakpoints.up('md')]: {
          //     width: 'auto'
          // },
          // [theme.breakpoints.up('lg')]: {
          //     width: 'auto'
          // },
          '&:hover': {
            color: '#e62949',
            transition: '0.7s'
          }
        },
        grid: {
          maxWidth: 1005,
          [theme.breakpoints.down('sx')]: {
            paddingTop: 50,
          },
          [theme.breakpoints.down('sm')]: {
            paddingTop: 50,
          },
        },
        drawerSubLinks: {
          fontSize: 13,
          lineHeight: 2.62,
          color: '#9a9da6',
          textDecoration: 'none',
          textTransform: 'uppercase',
          fontFamily: 'Josefin Sans,sans-serif',
          margin: '0px 24px',
          [theme.breakpoints.up('xs')]: {
            fontSize: 11,
          },
          [theme.breakpoints.up('sm')]: {
            fontSize: 12,
          },
          [theme.breakpoints.up('md')]: {
            fontSize: 13,
          },
          [theme.breakpoints.up('lg')]: {
            fontSize: 13,
          },
          '&:hover': {
            color: '#e62949',
            transition: '0.7s'
          }
        }
    }
})


const SideDrawer = () => {
    const classes = useStyles();
    const dispatch = useDispatch();

    const [open, setOpen] = React.useState(false);
    const [navMenuBtn, setNavMenuBtn] = React.useState(<MenuIcon className={classes.toggleBtn}/>);
    
      const toggleDrawer = (isOpen) => (event) => {
        if (event.target.outerHTML.includes('Close') || event.target.outerHTML.includes('M19')) {
            dispatch(closeNavbar());
            setNavMenuBtn(<MenuIcon className={classes.toggleBtn}/>);

            // enable scrolling on sidedrawer close
            document.getElementsByTagName('html')[0].style.overflow = "auto";

            return setOpen(false);
        } else if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
          return;
        }
        
        if (isOpen) {
          dispatch(activateNavbar());
          setNavMenuBtn(<CloseIcon className={classes.toggleBtn} style={{color: 'red'}}/>);

          // disable scrolling on sidedrawer open
          document.getElementsByTagName('html')[0].style.overflow = "hidden";
        } else {
          dispatch(closeNavbar());
          setNavMenuBtn(<MenuIcon className={classes.toggleBtn}/>);

          // enable scrolling on sidedrawer close
          document.getElementsByTagName('html')[0].style.overflow = "auto";
        }
        setOpen(isOpen);
      };
  

      return (
        <div className={classes.drawerContainer}>
            <React.Fragment>
              <IconButton
                  size="large"
                  edge="start"
                  aria-label="menu"
                  sx={{ mr: 2 }}
                  onClick={toggleDrawer(true)}
                  className={classes.toggleBtn}
              >
                  {navMenuBtn}
              </IconButton>
              <Drawer
                anchor='right'
                open={open}
                onClose={toggleDrawer(false)}
                style={{zIndex: 1}}
                transitionDuration={{ enter: 1000, exit: 1000 }}
              >
                <Box
                  role="presentation"
                  onClick={toggleDrawer(false)}
                  onKeyDown={toggleDrawer(false)}
                  className={classes.drawerBox}
                >
                  <Grid container direction="row" alignItems="center" justifyContent="center">
                    <Grid container direction="row" alignItems="center" justifyContent="center" className={classes.grid}>
                      <Grid item>
                          <Link to="/what-we-do/" className={classes.drawerLink}>What We Do</Link>
                      </Grid>
                      <Grid item>
                          <Link to="/how-it-works/" className={classes.drawerLink}>How It Works</Link>
                      </Grid>
                      <Grid item>
                          <Link to="/where-it-works/" className={classes.drawerLink}>Where It Works</Link>
                      </Grid>
                      <Grid item>
                          <Link to="/products/" className={classes.drawerLink}>Products</Link>
                      </Grid>
                      <Grid item>
                          <Link to="/" className={classes.drawerLink}>Our Team</Link>
                      </Grid>
                      <Grid item>
                          <Link to="/" className={classes.drawerLink}>History</Link>
                      </Grid>
                      <Grid item>
                          <Link to="/"  className={classes.drawerLink}>Newsroom</Link>
                      </Grid>
                      <Grid item>
                          <Link to="/" className={classes.drawerLink}>Cinema</Link>
                      </Grid>
                      <Grid item>
                          <Link to="/" className={classes.drawerLink}>Contact</Link>
                      </Grid>

                      <Grid container direction="row" alignItems="center" justifyContent="center" className={classes.grid} style={{padding: 0}}>
                        <Grid item>
                            <Link to="/" className={classes.drawerSubLinks}>Client Portal</Link>
                        </Grid>
                        <Grid item>
                            <Link to="/" className={classes.drawerSubLinks}>Don't Click This</Link>
                        </Grid>
                        <Grid item>
                            <Link to="/" className={classes.drawerSubLinks}>Careers</Link>
                        </Grid>
                        <Grid item>
                            <Link to="/" className={classes.drawerSubLinks}>Facebook</Link>
                        </Grid>
                        <Grid item>
                            <Link to="/" className={classes.drawerSubLinks}>Twitter</Link>
                        </Grid>
                        <Grid item>
                            <Link to="/" className={classes.drawerSubLinks}>Linkedin</Link>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Box>
              </Drawer>
            </React.Fragment>
        </div>
      );
}
 
export default SideDrawer;