import { makeStyles } from '@material-ui/core';
import Grid from '@mui/material/Grid';
import { Link } from 'react-router-dom';

const useStyles = makeStyles(theme => {
    return {
        managementContainer: {
            display: 'flex',
            margin: 'auto',
            flexWrap: 'wrap',
            padding: '240px 0px',
            flexDirection: 'row',
            fontFamily: 'Proxima Nova,Helvetica,Arial,sans-serif !important',
            justifyContent: 'center',
            marginTop: '20px !important',
            borderBottom: '1px solid white'
        },
        profileWrapper: {
            marginLeft: '1% !important',
            marginRight: '1% !important',
            height: '16.1vw !important',
            marginBottom: '120px !important',
            [theme.breakpoints.up('xs')]: {
                height: '64vw !important'
            },
            [theme.breakpoints.up('sm')]: {
                height: '40vw !important'
            },
            [theme.breakpoints.up('md')]: {
                height: '24vw !important'
            },
            [theme.breakpoints.up('lg')]: {
                height: '16.1vw !important'
            },
            [theme.breakpoints.up('xl')]: {
                height: '16.1vw !important'
            }
        },
        profileWrapperLink: {
            textDecoration: 'none'
        },
        profileWrapperImageContainer: {
            width: '100%',
            height: '100%',
            position: 'relative',
            zIndex: 1,
            backgroundColor: '#9a9da6',
            paddingBottom: '0px !important'
        },
        profileWrapperImage: {
            height: '100%',
            width: '101%',
            objectFit: 'cover',
            objectPosition: '50% 12% !important'
        },
        profileWrapperContent: {
            width: '100%',
            padding: '20px 10px 20px 0px',
            zIndex: 2
        },
        profileWrapperContentName: {
            fontSize: '16px',
            marginBottom: 4,
            fontFamily: 'Josefin Sans,sans-serif !important'
        },
        profileWrapperContentRole: {
            fontSize: 14,
            paddingRight: 20
        },
        pageHeading: {
            color: 'white',
            fontFamily: 'Josefin Sans,sans-serif !important',
            top: '15%',
            position: 'absolute',
            fontWeight: 'bold',
            fontSize: '2.5rem',
            width: '100%',
            textAlign: 'center',
        }
    }
});

const Management = () => {
    const classes = useStyles();

    return (
        <div className={classes.mainContainer}>
            <h1 className={classes.pageHeading}>Management</h1>
        
            <Grid container className={classes.managementContainer} spacing={4}>
                <Grid item className={classes.profileWrapper} xs={10} sm={8} md={4} lg={2}>
                    <Link to="#" className={classes.profileWrapperLink}>
                        <div className={classes.profileWrapperImageContainer}>
                            <img className={classes.profileWrapperImage} src="https://scontent.flhe3-2.fna.fbcdn.net/v/t39.30808-6/274805676_3227944770767014_6771440100858679005_n.jpg?_nc_cat=109&ccb=1-5&_nc_sid=09cbfe&_nc_ohc=5cXR8EAzc3UAX9xaDED&_nc_ht=scontent.flhe3-2.fna&oh=00_AT89B_kTPk7Gd4o6lYROQstyS-wZFOp4_vfd4V75axespg&oe=62697389" />
                        </div>
                        <div className={classes.profileWrapperContent}>
                            <div className={classes.profileWrapperContentName}>
                                Ahmad Manzoor
                            </div>
                            <div className={classes.profileWrapperContentRole}>
                                AI Researcher and Architect
                            </div>
                        </div>
                    </Link>
                </Grid>

                <Grid item className={classes.profileWrapper} xs={10} sm={8} md={4} lg={2}>
                    <Link to="#" className={classes.profileWrapperLink}>
                        <div className={classes.profileWrapperImageContainer}>
                            <img className={classes.profileWrapperImage} src="https://scontent.flhe7-1.fna.fbcdn.net/v/t31.18172-8/27788090_1992604227628338_1141590437314035627_o.jpg?_nc_cat=102&ccb=1-5&_nc_sid=174925&_nc_ohc=M7FCc_DvYAMAX__yH-F&_nc_ht=scontent.flhe7-1.fna&oh=00_AT_WFvbOZGd76YFH_rEwx8PeoWwGDwJHS4EKdEndsxEJJg&oe=62794FBA" />
                        </div>
                        <div className={classes.profileWrapperContent}>
                            <div className={classes.profileWrapperContentName}>
                                Salman Shahzada
                            </div>
                            <div className={classes.profileWrapperContentRole}>
                                Blockchain & FullStack Architect
                            </div>
                        </div>
                    </Link>
                </Grid>

                <Grid item className={classes.profileWrapper} xs={10} sm={8} md={4} lg={2}>
                    <Link to="#" className={classes.profileWrapperLink}>
                        <div className={classes.profileWrapperImageContainer}>
                            <img className={classes.profileWrapperImage} src="https://media-exp1.licdn.com/dms/image/C4E03AQF_PDE9QUzt-Q/profile-displayphoto-shrink_800_800/0/1614780602219?e=1655337600&v=beta&t=of-HmMlS7gfwuknUUizrcltKec9vWOCOTYjEiOJHfWU" />
                        </div>
                        <div className={classes.profileWrapperContent}>
                            <div className={classes.profileWrapperContentName}>
                                Ali Baig
                            </div>
                            <div className={classes.profileWrapperContentRole}>
                                AI Researcher and Architect
                            </div>
                        </div>
                    </Link>
                </Grid>

                <Grid item className={classes.profileWrapper} xs={10} sm={8} md={4} lg={2}>
                    <Link to="#" className={classes.profileWrapperLink}>
                        <div className={classes.profileWrapperImageContainer}>
                            <img className={classes.profileWrapperImage} src="https://media-exp1.licdn.com/dms/image/C5603AQGituZERnDO9Q/profile-displayphoto-shrink_800_800/0/1645024496447?e=1655337600&v=beta&t=V_XCIUc34j6q2M3xytG0x_n94CQVy6QIAOOQm4AH4GY" />
                        </div>
                        <div className={classes.profileWrapperContent}>
                            <div className={classes.profileWrapperContentName}>
                                Talha Khalid
                            </div>
                            <div className={classes.profileWrapperContentRole}>
                                AI Researcher and Architect
                            </div>
                        </div>
                    </Link>
                </Grid>
            </Grid>
        </div>
     );
}
 
export default Management;