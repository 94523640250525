import React from 'react';
import rightarrowicon from '../../custom-icons/right-arrow.svg';
import { makeStyles } from '@material-ui/core';
import { Link } from 'react-router-dom';
import Particles from "react-tsparticles";
import { loadFull } from "tsparticles";


const useStyles = makeStyles(theme => {
    return {
        brandRAIcon: {
            mask: `url(${rightarrowicon}) no-repeat center`
        },
        carouselInfo: {
            [theme.breakpoints.up('xs')]: {
                bottom: '17% !important',
                "& $carouselInfoHeadingMui": {
                    fontSize: "28px !important"
                }
            },
            [theme.breakpoints.up('sm')]: {
                bottom: '22% !important',
                "& $carouselInfoHeadingMui": {
                    fontSize: "40px !important"
                }
            },
            [theme.breakpoints.up('md')]: {
                bottom: '30% !important'
            },
            [theme.breakpoints.up('lg')]: {
                bottom: '30% !important'
            },
            [theme.breakpoints.up('xl')]: {
                bottom: '30% !important'
            }
        },
        carouselInfoHeadingMui: {
            fontSize: '40px !important'
        }
    }
}

)

const IndexCarousel = () => {
    const classes = useStyles();

    const particlesInit = async (main) => {
        console.log(main);

        // you can initialize the tsParticles instance (main) here, adding custom shapes or presets
        // this loads the tsparticles package bundle, it's the easiest method for getting everything ready
        // starting from v2 you can add only the features you need reducing the bundle size
        await loadFull(main);
    };

    const particlesLoaded = (container) => {
        console.log(container);
    };

    const carouselItems = [
        {
            src: "https://ds2btp74yml56.cloudfront.net/website/videos/v2/home-background-2.mp4",
            heading: 'Zariyat uses data and AI to transform the quality of interpersonal interactions.',
            link_text: 'Find out how we help businesses Pair Better'
        },
        // {
        //     src: "https://ds2btp74yml56.cloudfront.net/website/videos/v2/home-background-2.mp4",
        //     heading: 'Zariyat Enterprise Behavioral Pairing™ uses AI to identify subtle and valuable patterns in human behavior.',
        //     link_text: 'Find out how we use EBP™ to increase enterprise profitability'
        // },
        // {
        //     src: "https://ds2btp74yml56.cloudfront.net/website/videos/v2/home-background-3.mp4",
        //     heading: 'Last year, Zariyat delivered over $1 billion in proven revenue for our clients.',
        //     link_text: 'Find out how we deliver precisely measurable results'
        // }
    ]

    return ( 
        <div className='carousel-header'>
            <div className="carousel js-flickity" id="myCarousel" data-flickity='{ "autoPlay": false, "draggable": false, "freeScroll": false, "fade": true, "pauseAutoPlayOnHover": false, "wrapAround": true }'>
                {carouselItems.map((item, item_id) => (
                    <div className="carousel-cell" key={item_id}>
                        <a href="# " className={classes.carouselInfo + ' carousel-info'}>
                            <h1>
                                <p className={classes.carouselInfoHeadingMui + ' carousel-info-heading'}>
                                    {item.heading}
                                </p>
                            </h1>
                            <Link to="/what-we-do/" className='carousel-info-link'>
                                <span className='un'>
                                    {item.link_text}
                                </span>
                                <svg viewBox="0 0 152.2 37.4" className='carousel-info-link-arrow'>
                                    <g fill="#ffffff">
                                        <path d="M129 0l-1.4 3.3 17.1 13.3H0v4.2h144.7l-17.1 13.3 1.4 3.3 23.2-18.7z">
                                        </path>
                                    </g>
                                </svg>
                            </Link>
                        </a>

                        <div id="full_win_size">
                            <Particles
                                id="tsparticles"
                                init={particlesInit}
                                loaded={particlesLoaded}
                                options={{
                                    "autoPlay": true,
                                    "background": {
                                      "color": {
                                        "value": "#282e45"
                                      },
                                    //   "image": "url('/logo.png')",
                                      "position": "50% 50%",
                                      "repeat": "no-repeat",
                                      "size": "10%",
                                      "opacity": 1
                                    },
                                    "backgroundMask": {
                                      "composite": "destination-out",
                                      "cover": {
                                        "color": {
                                          "value": "#fff"
                                        },
                                        "opacity": 1
                                      },
                                      "enable": false
                                    },
                                    "fullScreen": {
                                      "enable": true,
                                      "zIndex": 1
                                    },
                                    "detectRetina": true,
                                    "duration": 0,
                                    "fpsLimit": 120,
                                    "interactivity": {
                                      "detectsOn": "window",
                                      "events": {
                                        "onClick": {
                                          "enable": true,
                                          "mode": "push"
                                        },
                                        "onDiv": {
                                          "selectors": [],
                                          "enable": false,
                                          "mode": [],
                                          "type": "circle"
                                        },
                                        "onHover": {
                                          "enable": true,
                                          "mode": "repulse",
                                          "parallax": {
                                            "enable": false,
                                            "force": 2,
                                            "smooth": 10
                                          }
                                        },
                                        "resize": true
                                      },
                                      "modes": {
                                        "attract": {
                                          "distance": 200,
                                          "duration": 0.4,
                                          "easing": "ease-out-quad",
                                          "factor": 1,
                                          "maxSpeed": 50,
                                          "speed": 1
                                        },
                                        "bounce": {
                                          "distance": 200
                                        },
                                        "bubble": {
                                          "distance": 400,
                                          "duration": 2,
                                          "mix": false,
                                          "opacity": 0.8,
                                          "size": 40,
                                          "divs": {
                                            "distance": 200,
                                            "duration": 0.4,
                                            "mix": false,
                                            "selectors": []
                                          }
                                        },
                                        "connect": {
                                          "distance": 80,
                                          "links": {
                                            "opacity": 0.5
                                          },
                                          "radius": 60
                                        },
                                        "grab": {
                                          "distance": 400,
                                          "links": {
                                            "blink": false,
                                            "consent": false,
                                            "opacity": 1
                                          }
                                        },
                                        "light": {
                                          "area": {
                                            "gradient": {
                                              "start": {
                                                "value": "#ffffff"
                                              },
                                              "stop": {
                                                "value": "#000000"
                                              }
                                            },
                                            "radius": 1000
                                          },
                                          "shadow": {
                                            "color": {
                                              "value": "#000000"
                                            },
                                            "length": 2000
                                          }
                                        },
                                        "push": {
                                          "default": true,
                                          "groups": [],
                                          "quantity": 4
                                        },
                                        "remove": {
                                          "quantity": 2
                                        },
                                        "repulse": {
                                          "distance": 200,
                                          "duration": 0.4,
                                          "factor": 100,
                                          "speed": 1,
                                          "maxSpeed": 50,
                                          "easing": "ease-out-quad",
                                          "divs": {
                                            "distance": 200,
                                            "duration": 0.4,
                                            "factor": 100,
                                            "speed": 1,
                                            "maxSpeed": 50,
                                            "easing": "ease-out-quad",
                                            "selectors": []
                                          }
                                        },
                                        "slow": {
                                          "factor": 3,
                                          "radius": 200
                                        },
                                        "trail": {
                                          "delay": 1,
                                          "pauseOnStop": false,
                                          "quantity": 1
                                        }
                                      }
                                    },
                                    "manualParticles": [],
                                    "motion": {
                                      "disable": false,
                                      "reduce": {
                                        "factor": 4,
                                        "value": true
                                      }
                                    },
                                    "particles": {
                                      "bounce": {
                                        "horizontal": {
                                          "random": {
                                            "enable": false,
                                            "minimumValue": 0.1
                                          },
                                          "value": 1
                                        },
                                        "vertical": {
                                          "random": {
                                            "enable": false,
                                            "minimumValue": 0.1
                                          },
                                          "value": 1
                                        }
                                      },
                                      "collisions": {
                                        "bounce": {
                                          "horizontal": {
                                            "random": {
                                              "enable": false,
                                              "minimumValue": 0.1
                                            },
                                            "value": 1
                                          },
                                          "vertical": {
                                            "random": {
                                              "enable": false,
                                              "minimumValue": 0.1
                                            },
                                            "value": 1
                                          }
                                        },
                                        "enable": false,
                                        "mode": "bounce",
                                        "overlap": {
                                          "enable": true,
                                          "retries": 0
                                        }
                                      },
                                      "destroy": {
                                        "mode": "none",
                                        "split": {
                                          "count": 1,
                                          "factor": {
                                            "random": {
                                              "enable": false,
                                              "minimumValue": 0
                                            },
                                            "value": 3
                                          },
                                          "rate": {
                                            "random": {
                                              "enable": false,
                                              "minimumValue": 0
                                            },
                                            "value": {
                                              "min": 4,
                                              "max": 9
                                            }
                                          },
                                          "sizeOffset": true
                                        }
                                      },
                                      "gradient": [],
                                      "groups": {},
                                      "life": {
                                        "count": 0,
                                        "delay": {
                                          "random": {
                                            "enable": false,
                                            "minimumValue": 0
                                          },
                                          "value": 0,
                                          "sync": false
                                        },
                                        "duration": {
                                          "random": {
                                            "enable": false,
                                            "minimumValue": 0.0001
                                          },
                                          "value": 0,
                                          "sync": false
                                        }
                                      },
                                      "links": {
                                        "blink": false,
                                        "color": {
                                          "value": "#ffffff"
                                        },
                                        "consent": false,
                                        "distance": 150,
                                        "enable": true,
                                        "frequency": 1,
                                        "opacity": 0.4,
                                        "shadow": {
                                          "blur": 5,
                                          "color": {
                                            "value": "#000"
                                          },
                                          "enable": false
                                        },
                                        "triangles": {
                                          "enable": false,
                                          "frequency": 1
                                        },
                                        "width": 1,
                                        "warp": false
                                      },
                                      "move": {
                                        "angle": {
                                          "offset": 0,
                                          "value": 90
                                        },
                                        "attract": {
                                          "distance": 200,
                                          "enable": false,
                                          "rotate": {
                                            "x": 600,
                                            "y": 1200
                                          }
                                        },
                                        "center": {
                                          "x": 50,
                                          "y": 50,
                                          "radius": 0
                                        },
                                        "decay": 0,
                                        "distance": {},
                                        "direction": "none",
                                        "drift": 0,
                                        "enable": true,
                                        "gravity": {
                                          "acceleration": 9.81,
                                          "enable": false,
                                          "inverse": false,
                                          "maxSpeed": 50
                                        },
                                        "path": {
                                          "clamp": true,
                                          "delay": {
                                            "random": {
                                              "enable": false,
                                              "minimumValue": 0
                                            },
                                            "value": 0
                                          },
                                          "enable": false,
                                          "options": {}
                                        },
                                        "outModes": {
                                          "default": "out",
                                          "bottom": "out",
                                          "left": "out",
                                          "right": "out",
                                          "top": "out"
                                        },
                                        "random": false,
                                        "size": false,
                                        "speed": 1,
                                        "spin": {
                                          "acceleration": 0,
                                          "enable": false
                                        },
                                        "straight": false,
                                        "trail": {
                                          "enable": false,
                                          "length": 10,
                                          "fillColor": {
                                            "value": "#000000"
                                          }
                                        },
                                        "vibrate": false,
                                        "warp": false
                                      },
                                      "number": {
                                        "density": {
                                          "enable": true,
                                          "area": 800,
                                          "factor": 1000
                                        },
                                        "limit": 0,
                                        "value": 80
                                      },
                                      "opacity": {
                                        "random": {
                                          "enable": true,
                                          "minimumValue": 0.1
                                        },
                                        "value": {
                                          "min": 0.1,
                                          "max": 0.5
                                        },
                                        "animation": {
                                          "count": 0,
                                          "enable": true,
                                          "speed": 3,
                                          "sync": false,
                                          "destroy": "none",
                                          "startValue": "random",
                                          "minimumValue": 0.1
                                        }
                                      },
                                      "orbit": {
                                        "animation": {
                                          "count": 0,
                                          "enable": false,
                                          "speed": 1,
                                          "sync": false
                                        },
                                        "enable": false,
                                        "opacity": 1,
                                        "rotation": {
                                          "random": {
                                            "enable": false,
                                            "minimumValue": 0
                                          },
                                          "value": 45
                                        },
                                        "width": 1
                                      },
                                      "reduceDuplicates": false,
                                      "repulse": {
                                        "random": {
                                          "enable": false,
                                          "minimumValue": 0
                                        },
                                        "value": 0,
                                        "enabled": false,
                                        "distance": 1,
                                        "duration": 1,
                                        "factor": 1,
                                        "speed": 1
                                      },
                                      "roll": {
                                        "darken": {
                                          "enable": false,
                                          "value": 0
                                        },
                                        "enable": false,
                                        "enlighten": {
                                          "enable": false,
                                          "value": 0
                                        },
                                        "mode": "vertical",
                                        "speed": 25
                                      },
                                      "rotate": {
                                        "random": {
                                          "enable": false,
                                          "minimumValue": 0
                                        },
                                        "value": 0,
                                        "animation": {
                                          "enable": false,
                                          "speed": 0,
                                          "sync": false
                                        },
                                        "direction": "clockwise",
                                        "path": false
                                      },
                                      "shadow": {
                                        "blur": 0,
                                        "color": {
                                          "value": "#000"
                                        },
                                        "enable": false,
                                        "offset": {
                                          "x": 0,
                                          "y": 0
                                        }
                                      },
                                      "shape": {
                                        "options": {},
                                        "type": "circle"
                                      },
                                      "size": {
                                        "random": {
                                          "enable": true,
                                          "minimumValue": 1
                                        },
                                        "value": {
                                          "min": 0.1,
                                          "max": 5
                                        },
                                        "animation": {
                                          "count": 0,
                                          "enable": true,
                                          "speed": 20,
                                          "sync": false,
                                          "destroy": "none",
                                          "startValue": "random",
                                          "minimumValue": 0.1
                                        }
                                      },
                                      "stroke": {
                                        "width": 0
                                      },
                                      "tilt": {
                                        "random": {
                                          "enable": false,
                                          "minimumValue": 0
                                        },
                                        "value": 0,
                                        "animation": {
                                          "enable": false,
                                          "speed": 0,
                                          "sync": false
                                        },
                                        "direction": "clockwise",
                                        "enable": false
                                      },
                                      "twinkle": {
                                        "lines": {
                                          "enable": true,
                                          "frequency": 0.005,
                                          "opacity": 1,
                                          "color": {
                                            "value": "#22FF00"
                                          }
                                        },
                                        "particles": {
                                          "enable": true,
                                          "frequency": 0.01,
                                          "opacity": 1,
                                          "color": {
                                            "value": "#22FF00"
                                          }
                                        }
                                      },
                                      "wobble": {
                                        "distance": 5,
                                        "enable": false,
                                        "speed": 50
                                      },
                                      "zIndex": {
                                        "random": {
                                          "enable": false,
                                          "minimumValue": 0
                                        },
                                        "value": 0,
                                        "opacityRate": 1,
                                        "sizeRate": 1,
                                        "velocityRate": 1
                                      }
                                    },
                                    "pauseOnBlur": true,
                                    "pauseOnOutsideViewport": true,
                                    "responsive": [],
                                    "style": {},
                                    "themes": [],
                                    "zLayers": 100
                                  }}
                            />
                            {/* <video muted playsInline autoPlay loop className='carousel-video'>
                                <source src={item.src} type="video/mp4" />
                                Your browser does not support HTML5 video.
                            </video> */}
                        </div>
                    </div>
                ))}
            </div>
        </div>
     );
}
 
export default IndexCarousel;