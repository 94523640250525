import * as React from 'react';
import Box from '@mui/material/Box';
import { Grid, Typography } from '@mui/material';
import { makeStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import twicon from '../../custom-icons/twicon.svg';
import fbicon from '../../custom-icons/fbicon.svg';
import linicon from '../../custom-icons/linicon.svg';


const useStyles = makeStyles(theme => {
    return {
        footerContainer: {
            borderTop: '1px solid white'
        },
        simpleFooter: {
            position: 'relative',
            padding: '30px 5% !important',
            height: '100% !important',
            backgroundColor: '#282e45',
            [theme.breakpoints.up('xs')]: {
                display: 'none !important'
            },
            [theme.breakpoints.up('sm')]: {
                display: 'none !important'
            },
            [theme.breakpoints.up('md')]: {
                display: 'flex !important'
            },
            [theme.breakpoints.up('lg')]: {
                display: 'flex !important'
            },
            [theme.breakpoints.up('xl')]: {
                display: 'flex !important'
            }  
        },
        footerLogo: {
            paddingLeft: 0,
            padding: '0 5% 15px',
            margin: '30px 0 !important',
            color: 'white',
            zIndex: 10
        },
        footerCol: {
            margin: 0,
            maxWidth: 900,
            padding: '0px 20px',
            zIndex: 10,
            [theme.breakpoints.up('xs')]: {
                marginBottom: '40px !important'
            },
            [theme.breakpoints.up('sm')]: {
                marginBottom: '40px !important'
            },
            [theme.breakpoints.up('md')]: {
                marginBottom: '40px !important'
            },
            [theme.breakpoints.up('lg')]: {
                marginBottom: '0px !important'
            },
            [theme.breakpoints.up('xl')]: {
                marginBottom: '0px !important'
            }
        },
        footerColHeading: {
            fontFamily: 'Josefin Sans,sans-serif !important',
            padding: 0,
            fontSize: '16px !important',
            border: 'none',
            textTransform: 'capitalize',
            color: 'white',
            cursor: 'pointer',
            zIndex: 10,
            textDecoration: 'none',
            '&:hover': {
                color: '#e62949',
                transition: '0.7s'
              }
        },
        footerColText: {
            fontFamily: 'Proxima Nova,Helvetica,Arial,sans-serif !important',
            fontSize: '14px !important',
            width: '100% important',
            display: 'block !important',
            color: 'white',
            opacity: .7,
            margin: '7px 0px 0px !important',
            '&:hover': {
                opacity: 1,
                transition: '0.7s'
            }
        },
        footerLink: {
            textDecoration: 'none',
            color: 'white !important'
        },
        footerRights: {
            padding: 0,
            display: 'inline-block',
            fontSize: '14px',
            fontFamily: 'Proxima Nova,sans-serif',
            zIndex: 10,
            color: 'white'
        },
        gridContainer: {
            margin: '30px 0 !important',
            [theme.breakpoints.up('xs')]: {
                justifyContent: 'left'
            },
            [theme.breakpoints.up('sm')]: {
                justifyContent: 'left'
            },
            [theme.breakpoints.up('md')]: {
                justifyContent: 'left'
            },
            [theme.breakpoints.up('lg')]: {
                justifyContent: 'space-between'
            }
        },
        accordionFooter: {
            fontSize: 16,
            fontFamily: 'Josefin Sans,sans-serif',
            lineHeight: 20,
            margin: '0 !important',
            backgroundColor: 'transparent !important',
            [theme.breakpoints.up('xs')]: {
                display: 'block'
            },
            [theme.breakpoints.up('sm')]: {
                display: 'block'
            },
            [theme.breakpoints.up('md')]: {
                display: 'none'
            },
            [theme.breakpoints.up('lg')]: {
                display: 'none'
            },
            [theme.breakpoints.up('xl')]: {
                display: 'none'
            },
            '&:hover': {
                "& $accordionHeading": {
                    color: '#e62949 !important',
                    transition: '0.7s !important'
                }
            },
        },
        accordionHeading: {
            height: '100%',
            margin: '0 !important',
            fontFamily: 'Josefin Sans,sans-serif !important',
            color: 'white !important',
        },
        footerAccordionRights: {
            height: '100%',
            margin: '0 !important',
            fontSize: '14px !important',
            fontFamily: 'Proxima Nova,sans-serif !important',
            color: 'white !important',
        },
        accordionBrand: {
            fontSize: 16,
            fontFamily: 'Josefin Sans,sans-serif',
            lineHeight: 20,
            paddingTop: '60px !important',
            justifyContent: 'space-between',
            padding: '0 5% 15px !important',
            backgroundColor: 'transparent !important',
            color: 'white !important',
            [theme.breakpoints.up('xs')]: {
                display: 'block'
            },
            [theme.breakpoints.up('sm')]: {
                display: '!important'
            },
            [theme.breakpoints.up('md')]: {
                display: 'none !important'
            },
            [theme.breakpoints.up('lg')]: {
                display: 'none !important'
            },
            [theme.breakpoints.up('xl')]: {
                display: 'none !important'
            }
        },
        accordionBrandLinks: {
            justifyContent: 'space-between',
            '& img': {
                height: 16,
                width: 18,
                marginRight: 16,
                backgroundColor: 'white',
            },
            '&:hover': {
                "& img":{
                    backgroundColor: '#e62949',
                    transition: '0.7s'
                }
            },
        },
        accordionFooterContainer: {
            // background: "url(https://media.graphcms.com/resize=w:2100/quality=value:80/compress/o9DzlqMlQAWf8u3NTSzL) no-repeat",
            backgroundColor: '#282e45',
            backgroundPosition: '100% 100%',
            objectFit: 'cover',
            verticalAlign: 'middle',
            backgroundSize: 'cover',
        },
        brandFbIcon: {
            mask: `url(${fbicon}) no-repeat center`
        },
        brandLinIcon: {
            mask: `url(${linicon}) no-repeat center`
        },
        brandTwIcon: {
            mask: `url(${twicon}) no-repeat center`
        }
    }
})

const Footer = () => {
    const classes = useStyles();
    const [accordionExpanded, setAccordionExpanded] = React.useState(false);

    const accordionChange = (panel) => (event, isExpanded) => {
        setAccordionExpanded(isExpanded ? panel : false);
    };

    return (
        <div className={classes.footerContainer}>
        {/* simple footer */}
        <Grid container className={classes.simpleFooter}>
            <Grid item xs={2} sm={2} md={2} lg={2} className={classes.footerLogo}>
                <Typography variant='p' noWrap>
                    <Link to="/">
                        <img alt="" src='/logo.png' className="rounded-circle user_img" style={{"border": "none", height: 32}}/>
                    </Link>
                </Typography>
            </Grid>
            <Grid container item xs={10} sm={10} md={10} lg={10} className={classes.gridContainer}>
                <Grid item className={classes.footerCol}>
                    <Link to="/what-we-do/" className={classes.footerColHeading} style={{margin: '0px 0px 5px'}}>
                        What we do
                    </Link>

                    {/* <Link className={classes.footerLink} to="/"><Typography className={classes.footerColText}>Pair Better Using Patterns</Typography></Link> */}
                    <Link className={classes.footerLink} to="/how-it-works/"><Typography className={classes.footerColText}>How it works</Typography></Link>
                    <Link className={classes.footerLink} to="/where-it-works/"><Typography className={classes.footerColText}>Where it works</Typography></Link>

                    <Typography className={classes.footerColHeading} style={{margin: '25px 0px 5px'}}>
                        <Link className={classes.footerColHeading} to="/products">Products</Link>
                    </Typography>
                    {/* <Typography className={classes.footerColHeading}>
                        Covid 19 Resources
                    </Typography> */}
                </Grid>

                {/* <Grid item className={classes.footerCol}>
                    <Typography className={classes.footerColHeading} style={{margin: '0px 0px 5px'}}>
                        Clients
                    </Typography>

                    <Link className={classes.footerLink} to="/"><Typography className={classes.footerColText}>Caesars</Typography></Link>
                    <Link className={classes.footerLink} to="/"><Typography className={classes.footerColText}>AXA Direct Insurance</Typography></Link>
                    <Link className={classes.footerLink} to="/"><Typography className={classes.footerColText}>AA</Typography></Link>
                    <Link className={classes.footerLink} to="/"><Typography className={classes.footerColText}>G6 Hospitality</Typography></Link>
                </Grid> */}

                <Grid item className={classes.footerCol}>
                    <Typography className={classes.footerColHeading} style={{margin: '0px 0px 5px'}}>
                        Partners
                    </Typography>

                    <Link className={classes.footerLink} to="/"><Typography className={classes.footerColText}>Telephony</Typography></Link>
                    <Link className={classes.footerLink} to="/"><Typography className={classes.footerColText}>Channel</Typography></Link>
                    <Link className={classes.footerLink} to="/"><Typography className={classes.footerColText}>BPO</Typography></Link>
                </Grid>

                <Grid item className={classes.footerCol}>
                    <Link to="/management/" className={classes.footerColHeading} style={{margin: '0px 0px 5px'}}>
                        Our Team
                    </Link>

                    <Link className={classes.footerLink} to="/management/"><Typography className={classes.footerColText}>Board Of Directors</Typography></Link>
                    <Link className={classes.footerLink} to="/management/"><Typography className={classes.footerColText}>Advisory Board</Typography></Link>
                    <Link className={classes.footerLink} to="/management/"><Typography className={classes.footerColText}>Management</Typography></Link>
                    <Link className={classes.footerLink} to="/management/"><Typography className={classes.footerColText}>Local Leadership</Typography></Link>
                </Grid>

                <Grid item className={classes.footerCol}>
                    <Typography className={classes.footerColHeading} style={{margin: '0px 0px 5px'}}>
                        Newsroom
                    </Typography>

                    <Link className={classes.footerLink} to="/"><Typography className={classes.footerColText}>Zariyat in the News</Typography></Link>
                    <Link className={classes.footerLink} to="/"><Typography className={classes.footerColText}>What we're Doing</Typography></Link>
                    <Link className={classes.footerLink} to="/"><Typography className={classes.footerColText}>What We Think</Typography></Link>
                </Grid>

                <Grid item className={classes.footerCol}>
                    <Typography className={classes.footerColHeading} style={{margin: '0px 0px 5px'}}>
                        Zariyat Life
                    </Typography>

                    {/* <Link className={classes.footerLink} to="/"><Typography className={classes.footerColText}>Cinema</Typography></Link> */}
                    {/* <Link className={classes.footerLink} to="/"><Typography className={classes.footerColText}>History</Typography></Link> */}
                    <Link className={classes.footerLink} to="/"><Typography className={classes.footerColText}>Careers</Typography></Link>
                    <Link className={classes.footerLink} to="/"><Typography className={classes.footerColText}>Contact</Typography></Link>
                </Grid>
                    
                <Grid item className={classes.footerCol}>
                    <Typography className={classes.footerColHeading} style={{margin: '0px 0px 5px'}}>
                        <Link className={classes.accordionBrandLinks} to="/"><img alt="" className={classes.brandTwIcon}/></Link>
                        <Link className={classes.accordionBrandLinks} to="/"><img alt="" className={classes.brandFbIcon}/></Link>
                        <Link className={classes.accordionBrandLinks} to="/"><img alt="" className={classes.brandLinIcon}/></Link>
                    </Typography>

                    <Link className={classes.footerLink} to="/"><Typography className={classes.footerColText}>Don't Click This</Typography></Link>
                    {/* <Link className={classes.footerLink} to="/"><Typography className={classes.footerColText}>Client Portal</Typography></Link> */}
                    <Link className={classes.footerLink} to="/"><Typography className={classes.footerColText}>Patents</Typography></Link>
                    <Link className={classes.footerLink} to="/"><Typography className={classes.footerColText}>Privacy Policy</Typography></Link>
                    <Link className={classes.footerLink} to="/"><Typography className={classes.footerColText}>Cookies Consent</Typography></Link>
                </Grid>
            </Grid>                
            <Box className={classes.footerRights}>
                <Typography variant="p" className={classes.footerLogo} noWrap>© 2021 Zariyat. All rights reserved</Typography>
            </Box>
        </Grid>

        {/* accordion footer */}
        <div className={classes.accordionFooterContainer}>
        <Grid container direction="row" className={classes.accordionBrand}>
            <Grid item>
                <Typography>
                    <Link to="/">
                        <img alt="" src='/logo.png' className="rounded-circle user_img" style={{"border": "none", height: 32}}/>
                    </Link>
                </Typography>
            </Grid>
            <Grid item style={{lineHeight: 0}}>
                <Link className={classes.accordionBrandLinks} to="/"><img alt="" className={classes.brandTwIcon}/></Link>
                <Link className={classes.accordionBrandLinks} to="/"><img alt="" className={classes.brandFbIcon}/></Link>
                <Link className={classes.accordionBrandLinks} to="/"><img alt="" className={classes.brandLinIcon}/></Link>
            </Grid>
        </Grid>

        <Accordion expanded={accordionExpanded === 'panel1'} onChange={accordionChange('panel1')} className={classes.accordionFooter}>
            <AccordionSummary
                expandIcon={accordionExpanded === 'panel1' ? <CloseOutlinedIcon style={{color: 'white'}}  /> : <AddOutlinedIcon style={{color: 'white'}} />}
                aria-controls="panel1a-content"
                id="panel1a-header"
                
            >
                <Typography className={classes.accordionHeading}>What We Do</Typography>
            </AccordionSummary>
            <AccordionDetails>
                <Link className={classes.footerLink} to="/what-we-do/"><Typography className={classes.footerColText}>Overview</Typography></Link>
                {/* <Link className={classes.footerLink} to="/"><Typography className={classes.footerColText}>Pair Better Using Patterns</Typography></Link> */}
                <Link className={classes.footerLink} to="/how-it-works/"><Typography className={classes.footerColText}>How it works</Typography></Link>
                <Link className={classes.footerLink} to="/where-it-works/"><Typography className={classes.footerColText}>Where it works</Typography></Link>
            </AccordionDetails>
        </Accordion>

        <Accordion expanded={accordionExpanded === 'panel2'} onChange={function(){}} className={classes.accordionFooter}>
            <AccordionSummary
                expandIcon=''
                aria-controls="panel2a-content"
                id="panel2a-header"
            >
                <Typography className={classes.accordionHeading}>
                    <Link className={classes.footerColHeading} to="products">Products</Link>    
                </Typography>
            </AccordionSummary>
        </Accordion>

        {/* <Accordion expanded={accordionExpanded === 'panel3'} onChange={function(){}} className={classes.accordionFooter}>
            <AccordionSummary
                expandIcon=''
                aria-controls="panel3a-content"
                id="panel3a-header"
                
            >
                <Typography className={classes.accordionHeading}>COVID-19 Resources</Typography>
            </AccordionSummary>
        </Accordion> */}

        <Accordion expanded={accordionExpanded === 'panel4'} onChange={accordionChange('panel4')} className={classes.accordionFooter}>
            <AccordionSummary
                expandIcon={accordionExpanded === 'panel4' ? <CloseOutlinedIcon style={{color: 'white'}}  /> : <AddOutlinedIcon style={{color: 'white'}} />}
                aria-controls="panel4a-content"
                id="panel4a-header"
                
            >
                <Typography className={classes.accordionHeading}>Clients</Typography>
            </AccordionSummary>
            <AccordionDetails>
                <Link className={classes.footerLink} to="/"><Typography className={classes.footerColText}>Overview</Typography></Link>
                <Link className={classes.footerLink} to="/"><Typography className={classes.footerColText}>Caesars</Typography></Link>
                <Link className={classes.footerLink} to="/"><Typography className={classes.footerColText}>AXA Direct Insurance</Typography></Link>
                <Link className={classes.footerLink} to="/"><Typography className={classes.footerColText}>AA</Typography></Link>
                <Link className={classes.footerLink} to="/"><Typography className={classes.footerColText}>G6 Hospitality</Typography></Link>
            </AccordionDetails>
        </Accordion>

        <Accordion expanded={accordionExpanded === 'panel5'} onChange={accordionChange('panel5')} className={classes.accordionFooter}>
            <AccordionSummary
                expandIcon={accordionExpanded === 'panel5' ? <CloseOutlinedIcon style={{color: 'white'}}  /> : <AddOutlinedIcon style={{color: 'white'}} />}
                aria-controls="panel5a-content"
                id="panel5a-header"
            >
                <Typography className={classes.accordionHeading}>Partners</Typography>
            </AccordionSummary>
            <AccordionDetails>
                <Link className={classes.footerLink} to="/"><Typography className={classes.footerColText}>Overview</Typography></Link>
                <Link className={classes.footerLink} to="/"><Typography className={classes.footerColText}>Telephony</Typography></Link>
                <Link className={classes.footerLink} to="/"><Typography className={classes.footerColText}>Channel</Typography></Link>
                <Link className={classes.footerLink} to="/"><Typography className={classes.footerColText}>BPO</Typography></Link>
            </AccordionDetails>
        </Accordion>

        <Accordion expanded={accordionExpanded === 'panel6'} onChange={accordionChange('panel6')} className={classes.accordionFooter}>
            <AccordionSummary
                expandIcon={accordionExpanded === 'panel6' ? <CloseOutlinedIcon style={{color: 'white'}}  /> : <AddOutlinedIcon style={{color: 'white'}} />}
                aria-controls="panel6a-content"
                id="panel6a-header"
                
            >
                <Typography className={classes.accordionHeading}>Our Team</Typography>
            </AccordionSummary>
            <AccordionDetails>
                <Link className={classes.footerLink} to="/management/"><Typography className={classes.footerColText}>Overview</Typography></Link>
                <Link className={classes.footerLink} to="/management/"><Typography className={classes.footerColText}>Board Of Directors</Typography></Link>
                <Link className={classes.footerLink} to="/management/"><Typography className={classes.footerColText}>Advisory Board</Typography></Link>
                <Link className={classes.footerLink} to="/management/"><Typography className={classes.footerColText}>Management</Typography></Link>
                <Link className={classes.footerLink} to="/management/"><Typography className={classes.footerColText}>Local Leadership</Typography></Link>
            </AccordionDetails>
        </Accordion>

        <Accordion expanded={accordionExpanded === 'panel7'} onChange={accordionChange('panel7')} className={classes.accordionFooter}>
            <AccordionSummary
                expandIcon={accordionExpanded === 'panel7' ? <CloseOutlinedIcon style={{color: 'white'}}  /> : <AddOutlinedIcon style={{color: 'white'}} />}
                aria-controls="panel7a-content"
                id="panel7a-header"
                
            >
                <Typography className={classes.accordionHeading}>Newsroom</Typography>
            </AccordionSummary>
            <AccordionDetails>
                <Link className={classes.footerLink} to="/"><Typography className={classes.footerColText}>Overview</Typography></Link>
                <Link className={classes.footerLink} to="/"><Typography className={classes.footerColText}>Zariyat in the News</Typography></Link>
                <Link className={classes.footerLink} to="/"><Typography className={classes.footerColText}>What we're Doing</Typography></Link>
                <Link className={classes.footerLink} to="/"><Typography className={classes.footerColText}>What We Think</Typography></Link>
            </AccordionDetails>
        </Accordion>

        <Accordion expanded={accordionExpanded === 'panel8'} onChange={accordionChange('panel8')} className={classes.accordionFooter}>
            <AccordionSummary
                expandIcon={accordionExpanded === 'panel8' ? <CloseOutlinedIcon style={{color: 'white'}}  /> : <AddOutlinedIcon style={{color: 'white'}} />}
                aria-controls="panel8a-content"
                id="panel8a-header"
                
            >
                <Typography className={classes.accordionHeading}>Zariyat Life</Typography>
            </AccordionSummary>
            <AccordionDetails>
                {/* <Link className={classes.footerLink} to="/"><Typography className={classes.footerColText}>Cinema</Typography></Link> */}
                {/* <Link className={classes.footerLink} to="/"><Typography className={classes.footerColText}>History</Typography></Link> */}
                <Link className={classes.footerLink} to="/"><Typography className={classes.footerColText}>Careers</Typography></Link>
                <Link className={classes.footerLink} to="/"><Typography className={classes.footerColText}>Contact</Typography></Link>
            </AccordionDetails>
        </Accordion>

        <Accordion expanded={accordionExpanded === 'panel9'} onChange={accordionChange('panel9')} className={classes.accordionFooter}>
            <AccordionSummary
                expandIcon={accordionExpanded === 'panel9' ? <CloseOutlinedIcon style={{color: 'white'}}  /> : <AddOutlinedIcon style={{color: 'white'}} />}
                aria-controls="panel9a-content"
                id="panel9a-header"
                
            >
                <Typography className={classes.accordionHeading}>Resources</Typography>
            </AccordionSummary>
            <AccordionDetails>
                <Link className={classes.footerLink} to="/"><Typography className={classes.footerColText}>Don't Click This</Typography></Link>
                {/* <Link className={classes.footerLink} to="/"><Typography className={classes.footerColText}>Client Portal</Typography></Link> */}
                <Link className={classes.footerLink} to="/"><Typography className={classes.footerColText}>Patents</Typography></Link>
                <Link className={classes.footerLink} to="/"><Typography className={classes.footerColText}>Privacy Policy</Typography></Link>
                <Link className={classes.footerLink} to="/"><Typography className={classes.footerColText}>Cookies Consent</Typography></Link>
            </AccordionDetails>
        </Accordion>

        <Accordion expanded={accordionExpanded === 'panel10'} onChange={function(){}} className={classes.accordionFooter}>
            <AccordionSummary
                expandIcon=''
                aria-controls="panel10a-content"
                id="panel10a-header"
            >
                <Typography className={classes.footerAccordionRights}>© 2021 Zariyat. All rights reserved</Typography>
            </AccordionSummary>
        </Accordion>
        </div>
        
        </div>
     );
}
 
export default Footer;