import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { defaultNavStatusActivated } from "./redux/ducks/navbarActive";

export default function ScrollToTop() {
  const { pathname } = useLocation();
  const dispatch = useDispatch();
  dispatch(defaultNavStatusActivated());

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}