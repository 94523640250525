import * as React from 'react';

import axios from 'axios';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { makeStyles } from '@material-ui/core/styles';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { Button } from '@mui/material';


const useStyles = makeStyles(theme => ({
    translationBody: {
        height: '100vh',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: '#282e45',
        borderBottom: '1px solid white',
        flexWrap: 'wrap',
        flexDirection: 'column'
    },
    translationContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        boxSizing: 'border-box',
        borderRadius: '8px',
        borderImageSource: 'linear-gradient(45deg, #743ad5, #d53a9d) !important',
        border: '2px solid !important',
        borderImageSlice: '1 !important',
        borderWidth: '2px !important',
        [theme.breakpoints.up('xs')]: {
            flexDirection: 'column'
        },
        [theme.breakpoints.up('sm')]: {
            flexDirection: 'column'
        },
        [theme.breakpoints.up('md')]: {
            flexDirection: 'row'
        },
        [theme.breakpoints.up('lg')]: {
            flexDirection: 'row'
        },
    },
    textAreaContainer: {
        display: 'flex',
        flexDirection: 'column',
    },
    sourceTextArea: {
        width: '25vw',
        height: '18vh',
        borderRight: '0px solid white',
        resize: 'none',
        borderTop: '0px solid white',
        fontSize: '24px',
        [theme.breakpoints.up('xs')]: {
            width: '75vw'
        },
        [theme.breakpoints.up('sm')]: {
            width: '80vw'
        },
        [theme.breakpoints.up('md')]: {
            width: '40vw'
        },
        [theme.breakpoints.up('lg')]: {
            width: '38vw'
        }
    },
    targetTextArea: {
        width: '25vw',
        height: '18vh',
        borderTop: '0px solid white',
        fontSize: '24px',
        resize: 'none',
        [theme.breakpoints.up('xs')]: {
            width: '75vw'
        },
        [theme.breakpoints.up('sm')]: {
            width: '80vw'
        },
        [theme.breakpoints.up('md')]: {
            width: '40vw'
        },
        [theme.breakpoints.up('lg')]: {
            width: '38vw'
        }
    },
    transSourceSelect: {
        color: 'red',
        border: '0px solid transparent',
        paddingLeft: '10px'
    },
    transTargetSelect: {
        color: '#1967d2',
        border: '0px solid transparent',
        paddingLeft: '10px'
    },
    translateFormBtn: {
        marginTop: '20px !important',
        float: 'right !important',
        borderImageSource: 'linear-gradient(45deg, #743ad5, #d53a9d) !important',
        border: '2px solid !important',
        borderImageSlice: '1 !important',
        borderWidth: '1px !important',
        background: 'linear-gradient(45deg, #743ad5, #d53a9d)',
        '-webkit-background-clip': 'text',
        '-webkit-text-fillColor': 'transparent',
        borderRadius: '0px !important',
        opacity: '.7',
        '&:hover': {
            opacity: '1'
        }
    },
    translateOptionsContainer: {
        width: '25vw',
        [theme.breakpoints.up('xs')]: {
            width: '90vw'
        },
        [theme.breakpoints.up('sm')]: {
            width: '90vw'
        },
        [theme.breakpoints.up('md')]: {
            width: '93vw'
        },
        [theme.breakpoints.up('lg')]: {
            width: '85vw'
        }
    },
    pageHeading: {
        color: 'white',
        fontFamily: 'Josefin Sans,sans-serif !important',
        top: '16%',
        position: 'absolute',
        fontWeight: 'bold',
        fontSize: '2.5rem'
    }
})
)


const Translation = () => {
    const classes = useStyles();

    const [srcLang, setSrcLang] = React.useState('');
    const [tgtLang, setTgtLang] = React.useState('');
    const [inpSrcText, setInpSrcText] = React.useState('');
    const [inpTgtText, setInpTgtText] = React.useState('');
    const [languages, setLanguages] = React.useState([]);

    const translationEndpoint = 'https://api.zariyat.com/translation/';

    const handleChangeSrcLang = (event, newValue) => {
        if (newValue) {
            setSrcLang(newValue.value)
        } else {
            setSrcLang('')
        }
    };

    const handleChangeTgtLang = (event, newValue) => {
        if (newValue) {
            setTgtLang(newValue.value)
        } else {
            setTgtLang('')
        }
    };

    const handleChangeInpSrcText = (event) => {
        setInpSrcText(event.target.value)
    };

    const handleChangeInpTgtText = (event) => {
        setInpTgtText(event.target.value)
    };

    React.useEffect(()=> {
        axios.get(translationEndpoint)
        .then(data => setLanguages(data.data))
    }, [])

    const submitTranslateForm = (e) => {
        e.preventDefault();

        if (inpSrcText && tgtLang && srcLang) {
            e.target.disabled = true;
            e.target.innerText = 'Translating';

            axios.post(translationEndpoint, {'src_lang': srcLang, 'tgt_lang': tgtLang, 'text': inpSrcText})
            .then(data => {setInpTgtText(data.data)})
            .catch(err => {console.log(err)})
            .finally(d => {e.target.disabled = false;e.target.innerText = 'Translate'})
        }
        
        if (!inpSrcText) {
            alert('Please input text to translate!')
        } else if (!tgtLang && !srcLang) {
            alert("Please select source and target languages!")
        }
    }

    return (
        <div>
            <li className='top-spacer'></li>
            
            <div className={classes.translationBody}>
                <span className={classes.pageHeading}>Translate</span>
                <div className={classes.translationContainer}>
                    <div className={classes.textAreaContainer}>
                        <Autocomplete
                            disablePortal
                            id="transSourceInput"
                            options={languages}
                            renderInput={(params) => <TextField {...params}  variant="standard" label="Source Language"/>}
                            className={classes.transSourceSelect}
                            popupIcon={<KeyboardArrowDownIcon style={{color: 'white'}}/>}
                            clearIcon={<CloseOutlinedIcon style={{color: 'white'}}/>}
                            onChange={(event, newValue) => handleChangeSrcLang(event, newValue)}
                            isOptionEqualToValue={(option, value) => option.value === value.value}
                        />
                        <textarea className={classes.sourceTextArea} value={inpSrcText} onChange={handleChangeInpSrcText}>

                        </textarea>
                    </div>

                    <div className={classes.textAreaContainer}>
                        <Autocomplete
                            disablePortal
                            id="transTargetInput"
                            options={languages}
                            renderInput={(params) => <TextField {...params}  variant="standard" label="Target Language" />}
                            className={classes.transTargetSelect}
                            popupIcon={<KeyboardArrowDownIcon style={{color: 'white'}}/>}
                            clearIcon={<CloseOutlinedIcon style={{color: 'white'}}/>}
                            onChange={(event, newValue) => handleChangeTgtLang(event, newValue)}
                            isOptionEqualToValue={(option, value) => option.value === value.value}
                        />
                        <textarea className={classes.targetTextArea} value={inpTgtText} onChange={handleChangeInpTgtText} readOnly>

                        </textarea>
                    </div>
                    
                </div>
                <div className={classes.translateOptionsContainer}>
                    <Button variant='outlined' onClick={submitTranslateForm} className={classes.translateFormBtn}>Translate</Button>
                </div>
            </div>
        </div>
     );
}

export default Translation;