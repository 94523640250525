import { makeStyles } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { howItWorksActivated } from "../../redux/ducks/navbarActive";

const useStyles = makeStyles(theme => {
    return {
        mainBgContainer: {
            position: 'relative',
            height: '100vh',
            width: '100vw',
            backgroundSize: 'cover',
            overflow: 'hidden',
        },
        bgImage: {
            top:'50%',
            left:'50%',
            width: '100%',
            height: '100%',
            minWidth:'1920px',
            minHeight:'1080px',
            position:'relative',
            transform:'translate(-50%, -50%)'
        },
        bannerContent: {
            bottom: '15vh',
            position: 'absolute',
            padding: '0 8%',
            maxWidth: '950px',
            fontFamily: 'Josefin Sans,sans-serif !important'
        },
        bannerText: {
            fontSize: '50px !important',
            fontFamily: 'Proxima Nova,Helvetica,Arial,sans-serif !important',
            letterSpacing: '-1.2px',
            fontWeight: '300 !important'
        },
        contentContainer: {
            padding: '90px 0px 90px',
            backgroundColor: 'white'
        },
        contentHeading: {
            maxWidth: '600px',
            margin: '40px auto',
            fontSize: '22px',
            lineHeight: '1.6',
            fontWeight: 300,
            color: '#9a9da6',
            padding: '0 5%',
        },
        contentParagraph: {
            color: '#666c78',
            lineHeight: '2.1',
            maxWidth: '600px',
            margin: '40px auto',
            padding: '0 5%',
            fontFamily: 'Proxima Nova,Helvetica,Arial,sans-serif !important',

        },
        contentLink: {
            color: 'red',
            textDecoration: 'none',
            fontFamily: 'Proxima Nova,Helvetica,Arial,sans-serif !important',
            '&:hover': {
                '& $contentLinkArrow': {
                    transform: 'translateX(10px) !important'
                }
            }
        },
        contentLinkArrow: {
            width: '30px',
            transform: '.4s ease-out',
            '-webkit-transform': '.4s ease-out',
            transition: '.4s ease-out',
            marginLeft: '10px',
            marginRight: 10
        },
        backToHomeContainer: {
            alignItems: 'center',
            marginBottom: 10,
            fontSize: '13px',
            textTransform: 'uppercase',
            fontFamily: 'Josefin Sans,sans-serif !important',
            '&:hover': {
                '& $carouselInfoLinkArrow': {
                    transform: 'translateX(-10px) rotate(180deg) !important'
                }
            }
        },
        backToHomeLink: {
            color: 'white',
            textDecoration: 'none'
        },
        carouselInfoLinkArrow: {
            width: '30px',
            '-webkit-transform': '.4s ease-out',
            transition: '.4s ease-out',
            marginLeft: '10px',
            transform: 'rotate(180deg)',
            marginRight: 10
        },
        contentParagraphHeading: {
            color: '#e62649',
            fontFamily: 'Proxima Nova,Helvetica,Arial,sans-serif !important',

        }
    }
});

const HowItWorks = () => {
    const classes = useStyles();
    const dispatch = useDispatch();
    dispatch(howItWorksActivated());

    return ( 
        <div className="whatWeDoContainer">
            <div className={classes.mainBgContainer}>
                <img src="/images/how_it_works_bg.jpg" className={classes.bgImage} alt="404"/>
            </div>
            <div className={classes.bannerContent}>
                
                <h1 className={classes.bannerText}>
                    <div className={classes.backToHomeContainer}>
                        <Link className={classes.backToHomeLink} to="/">
                            <svg viewBox="0 0 152.2 37.4" className={classes.carouselInfoLinkArrow}>
                                <g fill="#ffffff">
                                    <path d="M129 0l-1.4 3.3 17.1 13.3H0v4.2h144.7l-17.1 13.3 1.4 3.3 23.2-18.7z">
                                    </path>
                                </g>
                            </svg>

                            HOME
                        </Link>
                    </div>

                    <p>We Driver Better Conversations</p>
                </h1>
            </div>

            <section className={classes.contentContainer}>
                <div>
                    <h2 className={classes.contentHeading}>
                        Discover the transformative power of patterns
                    </h2>
                    <p className={classes.contentParagraph}>
                        Our technology is based on a simple idea: understand patterns of human behavior and you can predict how people will get along.
                    </p>
                    <p className={classes.contentParagraph}>
                        In a contact center, that means anticipating which agents and customers are most likely to click at any given time, so that when you connect them, agent, customer, and company all benefit.
                    </p>
                    <p className={classes.contentParagraph}>
                        Traditional call center software connects people solely on the basis of call order and availability. Zariyat’s AI identifies and predicts patterns across large sets of aggregated behavioral data to connect people on the basis of best fit.
                    </p>
                    <p className={classes.contentParagraph}>
                        With pairings made in milliseconds, our technology is imperceptible to customers and agents alike. Both parties simply have better conversations that lead to better outcomes — for both customer experience and company brand.
                    </p>

                    <p className={classes.contentParagraph}>
                        <strong className={classes.contentParagraphHeading}>Experience real results</strong><br/>
                        We know our technology works, but with Zariyat, you don’t have to take our word for it. You can see the results for yourself in our patented benchmarking system.
                    </p>
                    <p className={classes.contentParagraph}>
                        Throughout the day, our AI cycles on and off in short periods. When it's on, you’ll see that individuals report having better experiences, customers spend more over the long-term, and company revenues increase. This benchmarking system also controls for variables including market changes, holidays, and even weather.
                    </p>
                    <p className={classes.contentParagraph}>
                        You’ll only be charged a share of the value our technology delivers.
                    </p>

                    <p className={classes.contentParagraph}>
                        <strong className={classes.contentParagraphHeading}>Get started</strong><br/>
                        It typically takes between 25 and 100 days to get Zariyat up and running in client businesses, although it can take as few as five if your telephony provider is one of our partners.
                    </p>
                    <p className={classes.contentParagraph}>
                        You won’t need to make any changes to your existing contact center technology or agent recruitment, training, and compensation strategies.
                    </p>
                    <p className={classes.contentParagraph}>
                        In the typical Zariyat deployment, our clients don’t bear hardware, software, or professional services fees.
                    </p>

                    <p className={classes.contentParagraph} style={{height: '250px'}}>
                        <iframe src="https://player.vimeo.com/video/394632832?loop=false&autoplay=false&muted=false&gesture=media&playsinline=true&byline=false&portrait=false&title=false&speed=true&transparent=false&customControls=true" height='100%' width='100%' allowFullScreen="" allow="autoplay; fullscreen; picture-in-picture" title="Player for What We Do" data-ready="true" tabIndex="-1"></iframe>
                    </p>
                    
                    <h4 className={classes.contentParagraph}>
                        <Link to="/where-it-works" className={classes.contentLink}>
                            LEARN WHERE IT WORKS
                            <svg viewBox="0 0 152.2 37.4" className={classes.contentLinkArrow}>
                                <g fill="#f62e00">
                                    <path d="M129 0l-1.4 3.3 17.1 13.3H0v4.2h144.7l-17.1 13.3 1.4 3.3 23.2-18.7z">
                                    </path>
                                </g>
                            </svg>
                        </Link>
                    </h4>
                </div>
            </section>
        </div>
     );
}
 
export default HowItWorks;