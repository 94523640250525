import * as React from 'react';
import axios from 'axios';

import { makeStyles } from '@material-ui/core/styles';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import NativeSelect from '@mui/material/NativeSelect';
import { Button } from '@material-ui/core';


const useStyles = makeStyles(theme => ({
    summarizerBody: {
        paddingTop: '10vh',
        height: '100vh',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: '#282e45',
        borderBottom: '1px solid white',
        flexWrap: 'wrap',
        flexDirection: 'column',
        [theme.breakpoints.up('xs')]: {
            paddingTop: '10vh'
        },
        [theme.breakpoints.up('sm')]: {
            paddingTop: '10vh'
        },
        [theme.breakpoints.up('md')]: {
            paddingTop: '0vh'
        },
        [theme.breakpoints.up('lg')]: {
            paddingTop: '0vh'
        },
    },
    summarizerContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        boxSizing: 'border-box',
        borderRadius: '8px',
        borderImageSource: 'linear-gradient(45deg, #743ad5, #d53a9d) !important',
        border: '2px solid !important',
        borderImageSlice: '1 !important',
        borderWidth: '2px !important',
        [theme.breakpoints.up('xs')]: {
            flexDirection: 'column'
        },
        [theme.breakpoints.up('sm')]: {
            flexDirection: 'column'
        },
        [theme.breakpoints.up('md')]: {
            flexDirection: 'row'
        },
        [theme.breakpoints.up('lg')]: {
            flexDirection: 'row'
        },
    },
    textAreaContainer: {
        display: 'flex',
        flexDirection: 'column',
    },
    sourceTextArea: {
        width: '25vw',
        height: '30vh',
        borderRight: '0px solid white',
        resize: 'none',
        borderTop: '0px solid white',
        fontSize: '24px',
        [theme.breakpoints.up('xs')]: {
            width: '70vw'
        },
        [theme.breakpoints.up('sm')]: {
            width: '80vw'
        },
        [theme.breakpoints.up('md')]: {
            width: '40vw'
        },
        [theme.breakpoints.up('lg')]: {
            width: '38vw'
        }
    },
    targetTextArea: {
        width: '25vw',
        height: '30vh',
        borderTop: '0px solid white',
        fontSize: '24px',
        resize: 'none',
        [theme.breakpoints.up('xs')]: {
            width: '70vw'
        },
        [theme.breakpoints.up('sm')]: {
            width: '80vw'
        },
        [theme.breakpoints.up('md')]: {
            width: '40vw'
        },
        [theme.breakpoints.up('lg')]: {
            width: '38vw'
        }
    },
    transSourceSelect: {
        color: 'red',
        border: '0px solid transparent',
        paddingLeft: '10px'
    },
    transTargetSelect: {
        color: '#1967d2',
        border: '0px solid transparent',
        paddingLeft: '10px'
    },
    summarizeType: {
        color: 'white !important',
        width: '300px',
        "& .MuiSvgIcon-root": {
            color: "white",
        },
    },
    summarizeBtn: {
        marginTop: '10px !important',
        float: 'right !important',
        borderImageSource: 'linear-gradient(45deg, #743ad5, #d53a9d) !important',
        border: '2px solid !important',
        borderImageSlice: '1 !important',
        borderWidth: '1px !important',
        background: 'linear-gradient(45deg, #743ad5, #d53a9d)',
        '-webkit-background-clip': 'text',
        '-webkit-text-fillColor': 'transparent',
        borderRadius: '0px !important',
        opacity: '.7',
        '&:hover': {
            opacity: '1'
        }
    },
    summarizeOptionsContainer: {
        width: '25vw',
        [theme.breakpoints.up('xs')]: {
            width: '90vw'
        },
        [theme.breakpoints.up('sm')]: {
            width: '90vw'
        },
        [theme.breakpoints.up('md')]: {
            width: '93vw'
        },
        [theme.breakpoints.up('lg')]: {
            width: '85vw'
        }
    },
    pageHeading: {
        color: 'white',
        fontFamily: 'Josefin Sans,sans-serif !important',
        top: '16%',
        position: 'absolute',
        fontWeight: 'bold',
        fontSize: '2.5rem'
    }
})
)

const TextSummarization = () => {
    const classes = useStyles();

    const [inpSrcText, setInpSrcText] = React.useState('');
    const [inpTgtText, setInpTgtText] = React.useState('');

    const summarize_endpoint = 'https://api.zariyat.com/summarization/'

    const handleChangeInpSrcText = (event) => {
        setInpSrcText(event.target.value)
    };

    const handleChangeInpTgtText = (event) => {
        setInpTgtText(event.target.value)
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        e.target.disabled = true;
        e.target.innerText = "Summarizing";

        const summarize_type = document.getElementsByName('summarize-type')[0].value;

        if (inpSrcText === '') {
            alert("Please enter the text to summarize")
        }

        axios.post(summarize_endpoint, {'text': inpSrcText, 'type': summarize_type})
        .then(data => setInpTgtText(data.data))
        .catch(err => console.log(err))
        .finally(d => {e.target.disabled = false;e.target.innerText = "Summarize";})
    }

    return (
        <div>
            <li className='top-spacer'></li>

            <div className={classes.summarizerBody}>
                <span className={classes.pageHeading}>Summarization</span>
                <div className={classes.summarizeOptionsContainer}>
                    <FormControl>
                        <InputLabel variant="standard" htmlFor="uncontrolled-native" style={{color: 'white'}}>
                            Summary Type
                        </InputLabel>
                        <NativeSelect
                            className={classes.summarizeType}
                            defaultValue={30}
                            inputProps={{
                                name: 'summarize-type',
                                id: 'uncontrolled-native',
                            }}
                        >
                        <option style={{color:'black'}}>Simple</option>
                        <option style={{color:'black'}}>Financial</option>
                        </NativeSelect>
                    </FormControl>
                    
                </div>
                <div className={classes.summarizerContainer}>
                    <div className={classes.textAreaContainer}>
                        <textarea className={classes.sourceTextArea} value={inpSrcText} onChange={handleChangeInpSrcText} placeholder='Enter text to summarize...'>

                        </textarea>
                    </div>

                    <div className={classes.textAreaContainer}>
                        <textarea className={classes.targetTextArea} style={{color: 'green'}} value={inpTgtText} onChange={handleChangeInpTgtText} placeholder='Output...' readOnly>

                        </textarea>
                    </div>
                </div>
                <div className={classes.summarizeOptionsContainer}>
                    <Button variant='outlined' className={classes.summarizeBtn} onClick={handleSubmit}>Summarize</Button>
                </div>
            </div>
        </div>
     );
}
 
export default TextSummarization;