import axios from 'axios';
import { useEffect, useState } from 'react';
import '../../css/clients_instascrapping.css';

const Instascrapper = () => {
    const [totalData, setTotalData] = useState('N/A');
    const [updatedData, setUpdatedData] = useState('N/A');
    const [useableData, setUseableData] = useState('N/A');
    const dataEndpoint = "https://ucommerce.zariyat.com/apis/data_info/";

    useEffect(() => {
        axios.get(dataEndpoint).then(
            data => {
                setTotalData(data.data.counts_data.totalData);
                setUpdatedData(data.data.counts_data.updatedData);
                setUseableData(data.data.counts_data.useableData);
            }
        )
    }, [])

    return ( 
        <div>
            <li className='top-spacer'></li>
            <h1>Instagram Scrapping</h1>
            <div className='scrappingContainer'>

                <div className='infoCard'>
                    <span className='infoCardHeading'>
                        Total Data
                    </span>
                    <span className='infoCardDescription'>
                        {totalData}
                    </span>
                </div>

                <div className='infoCard'>
                    <span className='infoCardHeading'>
                        Updated Data
                    </span>
                    <span className='infoCardDescription'>
                        {updatedData}
                    </span>
                </div>

                <div className='infoCard'>
                    <span className='infoCardHeading'>
                        Useable Data
                    </span>
                    <span className='infoCardDescription'>
                        {useableData}
                    </span>
                </div>
            </div>

            <h1>User Classification</h1>
            <div className='userInfoDataTableContainer'>
                <div className='userInfoDataTable'>
                    <table id="instascrappingDatatable" className='instascrappingDatatableDisplay' style={{"width": "90%"}}>
                        <thead>
                            <tr>
                                <th>Username</th>
                                <th>Age</th>
                                <th>Race</th>
                                <th>Gender</th>
                            </tr>
                        </thead>
                        <tfoot>
                            <tr>
                                <th>Username</th>
                                <th>Age</th>
                                <th>Race</th>
                                <th>Gender</th>
                            </tr>
                        </tfoot>
                    </table>
                </div>
            </div>
            
        </div>
     );
}
 
export default Instascrapper;