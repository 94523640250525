// intial state
const initialState = {
    color: 'white'
}

// action types
const NAVBARACTIVE = 'navbaractive';
const NAVBARCLOSED = 'navbarclosed';

// redux actions
export const activateNavbar = () => ({
    type: NAVBARACTIVE
})

export const closeNavbar = () => ({
    type: NAVBARCLOSED
})

const navReducer = (state=initialState, action) => {
    switch(action.type) {
        case NAVBARACTIVE:
            return { ...state, color: 'black'}
        case NAVBARCLOSED:
            return { ...state, color: 'white'}
        default:
            return state
    }
}

export default navReducer;