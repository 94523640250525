import { makeStyles } from '@material-ui/core';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { useState } from 'react';
import Button from '@mui/material/Button';



const useStyles = makeStyles(theme => {
    return {
        mainBgContainer: {
            position: 'relative',
            height: '100vh',
            width: '100vw',
            backgroundSize: 'cover',
            overflow: 'hidden',
        },
        bgVideo: {
            top:'50%',
            left:'50%',
            width: '100%',
            height: '100%',
            minWidth:'1920px',
            minHeight:'1080px',
            position:'relative',
            transform:'translate(-50%, -50%)'
        },
        videoContentContainer: {
            position: 'absolute',
            maxWidth: 1660,
            padding: '150px 10% 0',
            alignItems: 'center',
            width: '100%',
            margin: 0,
            zIndex: 10,
            justifyContent: 'center',
            height: '100%',
            display: 'flex',
            overflow: 'overlay',
        },
        videoContentText: {
            maxWidth: '40%',
            paddingRight: '5%',
            marginLeft: 0,
            marginBottom: 0,
            display: 'flex',
            flexDirection: 'column !important',
            justifyContent: 'center !important'
        },
        videoContentForm: {
            padding: "45px !important",
            minWidth: '50% !important',
            marginLeft: '10%',
            background: '#fff',
            top: 0,
            [theme.breakpoints.up('xs')]: {
                minWidth: '100% !important'
            },
            [theme.breakpoints.up('sm')]: {
                minWidth: '100% !important'
            },
            [theme.breakpoints.up('md')]: {
                minWidth: '80% !important'
            },
            [theme.breakpoints.up('lg')]: {
                minWidth: '50% !important'
            },
            [theme.breakpoints.up('xl')]: {
                minWidth: '50% !important'
            }
        },
        videoContentP: {
            margin: 0,
            fontSize: 16,
            lineHeight: 1.67,
            opacity: .7,
            fontFamily: 'Proxima Nova,Helvetica,Arial,sans-serif !important'
        },
        videoContentH1: {
            fontSize: 30,
            fontWeight: 300,
            lineHeight: 1.27,
            margin: '0px 0px 17px',
            fontFamily: 'Josefin Sans,sans-serif !important'
        },
        videoContentFormTextField: {
            width: '100%',
            border: '0px !important',
            // borderBottom: '1px solid rgba(2,10,32,.1) !important',
            lineHeight: '40px !important',
            fontFamily: 'Josefin Sans,sans-serif !important',
            background: 'transparent !important',
            color: '#9a9da6 !important',
            fontSize: '16px !important',
            height: '75px',
        },
        videoContentFormTextAreaField: {
            width: '100%',
            border: '0px !important',
            lineHeight: '40px !important',
            fontFamily: 'Josefin Sans,sans-serif !important',
            background: 'transparent !important',
            color: '#9a9da6 !important',
            fontSize: '16px !important',
            marginBottom: '20px !important'
        },
        contactForm: {
            maxWidth: 690,
            margin: '0 auto',
            textAlign: 'left',
            padding: 0
        },
        submitBtn: {
            fontFamily: 'Josefin Sans,sans-serif !important',
            color: 'black !important'
        }
    }
});

const contactReason = [
    'BECOME A CLIENT',
    'INVESTMENT',
    'PARTNERSHIP',
    'PRESS ENQUIRY',
    'CAREERS'
]

const Contact = () => {
    const classes = useStyles();
    const [selContactReason, setSelContactReason] = useState('BECOME A CLIENT');
    const [contactName, setContactName] = useState('');
    const [contactEmail, setContactEmail] = useState('');
    const [contactPhone, setContactPhone] = useState('');
    const [contactQuery, setContactQuery] = useState('');

    const handleSubmit = (e) => {
        e.preventDefault();
        console.log({
            selContactReason,
            contactName,
            contactEmail,
            contactPhone,
            contactQuery,
        })
        // do something with form submitted
    }

    return ( 
        <div className='contact'>
            <div >
                <Grid className={classes.videoContentContainer} container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                    <Grid item xs={12} sm={10} md={8} lg={6} className={classes.videoContentText}>
                        <h1 className={classes.videoContentH1}>Get in touch</h1>
                        <p className={classes.videoContentP}>Get in touch to discuss business and partnership opportunities, press or careers.</p>
                    </Grid>
                    <Grid item xs={12} sm={10} md={8} lg={6} className={classes.videoContentForm}>
                        <form className={classes.contactForm}>
                        <FormControl fullWidth className={classes.videoContentFormTextField}>
                            <Select
                                labelId="topic-select-label"
                                value={selContactReason}
                                label="Age"
                                onChange={e =>  setSelContactReason(e.target.value)}
                                variant="standard"
                            >
                                {contactReason.map((reason, i) => <MenuItem value={reason} key={i}>{reason}</MenuItem>)}
                            </Select>
                        </FormControl>
                            <TextField onChange={e => setContactName(e.target.value)} value={contactName} className={classes.videoContentFormTextField} label="Name*" variant="standard" />
                            <TextField onChange={e => setContactEmail(e.target.value)} value={contactEmail} className={classes.videoContentFormTextField} label="Email*" variant="standard" />
                            <TextField onChange={e => setContactPhone(e.target.value)} value={contactPhone} className={classes.videoContentFormTextField} label="Phone*" variant="standard" />
                            <TextField onChange={e => setContactQuery(e.target.value)} value={contactQuery} className={classes.videoContentFormTextAreaField} label="Query*" multiline variant="standard" maxRows={8}/>
                            <Button onClick={handleSubmit} className={classes.submitBtn}>Submit</Button>
                        </form>
                    </Grid>
                </Grid>
            </div>

            <div className={classes.mainBgContainer}>
                <video muted loop playsInline autoPlay className={classes.bgVideo}>
                    <source src='https://ds2btp74yml56.cloudfront.net/website/videos/v2/contactus-background.mp4' type="video/mp4" />
                    Your browser does not support HTML5 video.
                </video>
            </div>
        </div>
     );
}
 
export default Contact;